import React, {useState} from 'react'
import {Table, ButtonToolbar, Button} from 'react-bootstrap'
import { Command } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import ResourceCreateButton from "../components/ResourceCreateButton"
import CommandModal from "../components/CommandModal"
import CommandRunModal from "../components/CommandRunModal"
import ExpandableList from "../lib/ExpandableList"
import {faPlay} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

const CommandIndex = ({organization}) => {
  const [modal, setModal] = useState(null)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource) => {
    setSelectedResource(resource)
    setModal('command')
  }

  const newResource = () => {
    setSelectedResource(Command.build({organization: organization}))
    setModal('command')
  }

  const closeModal = () => {
    setSelectedResource(null)
    setModal(null)
  }

  const runCommand = (resource) => {
    setSelectedResource(resource)
    setModal('commandRun')
  }

  const baseQuery = () => {
    if (organization)
      return organization.commands().includes('organization,userGroups')
    else
      return Command.includes('organization,userGroups')
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="commands">
          New command
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
        <tr>
          {!organization &&
          <th>Organization</th>}
          <ResourceListSortTh name="name"/>
          <th>Description</th>
          <th>User groups</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id}>
            {!organization &&
            <td>{resource.organization().name}</td>}
            <td>{resource.name}</td>
            <td>{resource.description}</td>
            <td>
              <ExpandableList>
                {resource.userGroups().toArray().sort((a, b) => a.name > b.name ? 1 : -1).map(group => <div key={group.uniqueName}>{group.uniqueName}</div>)}
              </ExpandableList>
            </td>
            <td className="actions">
              <Button onClick={() => runCommand(resource)} variant="link" title="Run command">
                <FontAwesomeIcon icon={faPlay} />
              </Button>
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)}/>
              <ResourceDeleteButton resource={resource}/>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <CommandModal resource={selectedResource} show={modal === 'command'} onClose={closeModal}/>
      <CommandRunModal resource={selectedResource} show={modal === 'commandRun'} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default CommandIndex
