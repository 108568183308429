import React, {useContext, useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import { ResourceContext } from "../contexts/ResourceContext"
import ResourceModal from "./ResourceModal"
import FormCheckbox from "../lib/FormCheckbox"
import UserInputs from "./UserInputs"
import UserModalRoles from "./UserModalRoles"
import { RootContext } from '../contexts/RootContext'
import FormInput from "../lib/FormInput"

const UserModal = ({resource, show, onClose}) => {
  const { auth, canCreate } = useContext(RootContext)
  const [ formState, setFormState ] = useState({})
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})
  const [ roles, setRoles ] = useState([])

  useEffect(
    () => {
      setErrors({})
      if (resource){
        let initialState = {}
        resource.editableAttributes().forEach(attr => initialState[attr] = resource[attr])
        setFormState({
          ...initialState,
          jwtExpHours: initialState.jwtExpirationSeconds ? (parseInt(initialState.jwtExpirationSeconds, 10) / 3600).toFixed(0) : ''
        })
        setRoles(resource.roles().toArray().sort((a, b) => (a.name > b.name) - (a.name < b.name)).map(r => ({
          resource: r,
          state: {roleType: r.roleType}
        })))
      }
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    setFormState({...formState, [name]: value})
  }

  const save = () => {
    const attr = {...formState}
    if (formState.jwtExpHours && !isNaN(formState.jwtExpHours)) {
      attr.jwtExpirationSeconds = (parseInt(formState.jwtExpHours, 10) * 3600).toFixed(0)
    } else {
      attr.jwtExpirationSeconds = ''
    }
    delete attr.jwtExpHours
    if (attr.disablePasswordAuth && !resource.persisted()) {
      // Create random password for new user if password is disabled as auth method
      const password = new Array(12).fill().map(() => String.fromCharCode(Math.random()*86+40)).join("")
      attr.password = password
      attr.passwordConfirmation = password
    }
    resource.assignAttributes(attr)
    const msg = resource.persisted() ? 'User updated' : 'User created'
    return resource.save().then(async () => {
      await deleteRoles()
      await upsertRoles()
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      console.log("Error saving the user: " + err)
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const deleteRoles = async () => {
    for(let role of resource.roles().toArray()){
      if (!roles.map(r => r.resource).includes(role) && role.canDestroy())
        await role.destroy()
    }
  }

  const upsertRoles = async () => {
    for(let role of roles){
      if ((role.resource.persisted() && role.resource.canUpdate()) ||
          (!role.resource.persisted() && canCreate('roles')))
        {
          role.resource.assignAttributes(role.state)
          await role.resource.save()
        }
    }
  }

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} resource={resource} label="user">
      <Form>
        {auth.user && auth.user.admin &&
        <FormCheckbox name="admin" checked={formState.admin || false} onChange={handleInputChange} horizontal/>}
        <UserInputs
          formState={formState}
          setFormState={setFormState}
          errors={errors}
          handleInputChange={handleInputChange}
        />
        <FormInput
          name="jwtExpHours"
          label="Session exp hours"
          placeholder="leave empty for default"
          value={formState.jwtExpHours}
          error={errors.jwtExpirationSeconds}
          onChange={handleInputChange}
          horizontal
        />
      </Form>
      <h5>Roles</h5>
      <UserModalRoles
        canCreateManagers={auth.user && auth.user.admin}
        user={resource}
        roles={roles}
        setRoles={setRoles}/>
    </ResourceModal>
  )
}

export default UserModal
