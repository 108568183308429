import React from "react"
import {Circle} from "@react-google-maps/api"
import {Button, Col, Row} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBullseye} from "@fortawesome/free-solid-svg-icons"
import FormCheckbox from "../lib/FormCheckbox"
import WorldMap from "../lib/WorldMap"
import FormInput from "../lib/FormInput"
import styles from "../styles/InstrumentAlarmModal.module.css"

const InstrumentAlarmModalGeofence = ({geofence, setGeofence, formState, setFormState, errors, onInputChange}) => {

  const toggleGeofence = () => {
    setGeofence(!geofence)
  }

  const handleMapClick = (event) => {
    setFormState({...formState, geofenceLatitude: event.latLng.lat(), geofenceLongitude: event.latLng.lng()})
  }

  const worldMapCenter = () => {
    if (worldMapMarkers().length > 0)
      return worldMapMarkers()[0]
    else
      return undefined
  }

  const worldMapMarkers = () => {
    if (formState.geofenceLatitude && formState.geofenceLongitude) {
      return [{
        lat: parseFloat(formState.geofenceLatitude),
        lng: parseFloat(formState.geofenceLongitude)
      }]
    } else
      return []
  }

  const trackLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        setFormState({...formState, latitude: position.coords.latitude, longitude: position.coords.longitude})
      })
    } else {
      console.error("No geolocation support")
    }
  }

  return (
    <React.Fragment>
      <FormCheckbox name="geofence" checked={geofence} onChange={toggleGeofence} />
      <div className={[styles.worldMapContainer, geofence && styles.worldMapContainerVisible].join(' ')}>
        <WorldMap mapContainerClassName={styles.worldMap}
                  onClick={handleMapClick}
                  markers={worldMapMarkers()}
                  center={worldMapCenter()}>
          {worldMapMarkers().length > 0 && formState.geofenceRadiusM &&
          <Circle center={worldMapMarkers()[0]}
            // required
                  options={{
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 1,
                    fillColor: '#FF0000',
                    fillOpacity: 0.25,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    radius: parseInt(formState.geofenceRadiusM, 10),
                    zIndex: 1
                  }}
          />}
        </WorldMap>
        <Row noGutters>
          <Col xs={4} style={{paddingRight: 5}}>
            <FormInput name="geofenceLatitude"
                       label="Latitude"
                       value={formState.geofenceLatitude}
                       error={errors.geofenceLatitude}
                       onChange={onInputChange}/>
          </Col>
          <Col xs={4} style={{paddingRight: 5}}>
            <FormInput name="geofenceLongitude"
                       label="Longitude"
                       value={formState.geofenceLongitude}
                       error={errors.geofenceLongitude}
                       onChange={onInputChange}/>
          </Col>
          <Col xs={1}>
            <Button title="Track current location"
                    variant="dark"
                    onClick={trackLocation} style={{marginTop: "32px", fontSize: "1em"}}>
              <FontAwesomeIcon icon={faBullseye} />
            </Button>
          </Col>
          <Col xs={3} style={{paddingLeft: 15}}>
            <FormInput name="geofenceRadiusM"
                       label="Radius (m)"
                       value={formState.geofenceRadiusM}
                       error={errors.geofenceRadiusM}
                       onChange={onInputChange}/>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default InstrumentAlarmModalGeofence
