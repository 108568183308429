import Base from './Base'

class User extends Base {
  static className = 'User'
  static define() {
    this.hasMany('roles')
    this.attributes(
      'name',
      'email',
      'password',
      'passwordConfirmation',
      'admin',
      'phoneNumber',
      'notifyEmail',
      'notifySms',
      'notifyPush',
      'jwtExpirationSeconds',
      'disablePasswordAuth'
    )
  }

  uniqueName(){
    return `${this.name} <${this.email}>`
  }
}

export default Base.createResource(User)