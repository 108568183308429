import React, {useState} from 'react'
import { Table, ButtonToolbar } from 'react-bootstrap'
import { InstrumentStatusReport } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import ResourceCreateButton from "../components/ResourceCreateButton"
import InstrumentStatusReportModal from "../components/InstrumentStatusReportModal"
import ResourceDisableButton from "../components/ResourceDisableButton"

const InstrumentStatusReportIndex = ({organization}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(InstrumentStatusReport.build({
      organization: organization
    }))
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const baseQuery = () => {
    if (organization) {
      return organization.instrumentStatusReports().includes('organization', 'tag')
    } else {
      return InstrumentStatusReport.includes('organization', 'tag')
    }
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="instrumentStatusReports">
          New status report
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
        <tr>
          {!organization &&
          <th>Organization</th>}
          <ResourceListSortTh name="reportType">Type</ResourceListSortTh>
          <ResourceListSortTh name="name"/>
          <th>Tag</th>
          <ResourceListSortTh name="interval"/>
          <ResourceListSortTh name="receiverEmails">Receiver email(s)</ResourceListSortTh>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id} className={`clickable ${resource.disabledAt ? 'disabled' : ''}`}>
            {!organization &&
            <td>{resource.organization().name}</td>}
            <td>{InstrumentStatusReport.reportTypes[resource.reportType]}</td>
            <td>{resource.name}</td>
            <td>{resource.tag() ? resource.tag().name : '-'}</td>
            <td>{InstrumentStatusReport.intervals[resource.interval]}</td>
            <td>{resource.receiverEmails}</td>
            <td className="actions">
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)} />
              <ResourceDisableButton resource={resource} />
              <ResourceDeleteButton resource={resource} />
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <InstrumentStatusReportModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default InstrumentStatusReportIndex
