import React, {useContext, useEffect, useState} from 'react'
import {Form, Button, ButtonToolbar} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import { RootContext } from "../contexts/RootContext"
import styles from '../styles/LoginForm.module.css'
import { useGoogleLogin } from '@react-oauth/google'
import GoogleAuthButton from "../components/GoogleAuthButton"
import useEntraLogin from '../hooks/useEntraLogin'
import EntraAuthButton from '../components/EntraAuthButton'

const SignInForm = () => {
  const [formState, setFormState] = useState({email: '', password: ''})
  const { signIn, isAuthenticated, defaultView, signInOauth } = useContext(RootContext)
  const history = useHistory()

  useEffect(
    () => {
      if (isAuthenticated())
        history.push(`/${defaultView}`)
    },
    []
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    const value = ev.target.value
    setFormState({...formState, [name]: value})
  }

  const handleSubmit = () => {
    const url = localStorage.getItem('returnUrl') || `/${defaultView}`
    signIn(formState, url)
  }

  // Scope:
  // email profile openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: codeResponse => {
      //console.log(codeResponse)
      const url = localStorage.getItem('returnUrl') || `/${defaultView}`
      signInOauth('google', codeResponse.code, url)
    },
    onError: () => { console.log('Google Login Failed') },
    flow: 'auth-code'
  })

  const handleEntraLogin = useEntraLogin({
    onSuccess: codeResponse => {
      //console.log(codeResponse)
      const url = localStorage.getItem('returnUrl') || `/${defaultView}`
      signInOauth('entra', codeResponse.code, url, process.env.REACT_APP_ENTRA_REDIRECT_URL)
    },
    onError: () => { console.log('Entra Login Failed') }
  })

  return (
    <Form className={styles.form}>
      <h1>Login</h1>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control autoFocus={true} type="email" name="email" value={formState.email} onChange={handleInputChange}/>
      </Form.Group>
      <Form.Group controlId="password">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" name="password" value={formState.password} onChange={handleInputChange} onKeyPress={event => {
          if (event.key === "Enter") {
            handleSubmit()
          }
        }}/>
      </Form.Group>
      <ButtonToolbar className="float-right">
        {/*
        <LinkContainer to="/">
          <Button variant="secondary">Cancel</Button>
        </LinkContainer>
        &nbsp;*/}
        <Button onClick={handleSubmit}>Login</Button>
      </ButtonToolbar>
      <Link to="/forgot_password">Forgot password?</Link>
      <br/>
      <br/>
      <hr/>
      <GoogleAuthButton onClick={handleGoogleLogin} />
      <EntraAuthButton onClick={handleEntraLogin} />
    </Form>
  )
}

export default SignInForm
