import React, {useContext, useEffect, useState} from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { ResourceContext } from "../contexts/ResourceContext"
import FormInput from '../lib/FormInput'
import FormSelect from "../lib/FormSelect"
import Instrument from "../resources/Instrument"
import ResourceModal from "./ResourceModal"
import FormCheckbox from "../lib/FormCheckbox"
import FormFile from "../lib/FormFile"
import UserGroupsSelector from "./UserGroupsSelector"
import InstrumentModalTags from "./InstrumentModalTags"
import InstrumentModalParent from "./InstrumentModalParent"

const InstrumentModal = ({resource, show, onClose}) => {
  const [ formState, setFormState ] = useState({})
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})
  const [ selectedUserGroups, setSelectedUserGroups ] = useState([])
  const [ selectedTags, setSelectedTags ] = useState([])
  const [ organization, setOrganization ] = useState(null)
  const [ parent, setParent ] = useState(null)
  const [ showDataGraphSettings, setShowDataGraphSettings ] = useState(false)

  useEffect(
    () => {
      if (resource){
        setOrganization(resource.organization())
        setParent(resource.parent())
        setFormState({
          instrumentType: resource.group ? 'group' : resource.instrumentType,
          group: resource.group,
          dataResolution: resource.dataResolution,
          excludedDataAttributes: resource.excludedDataAttributes,
          uiSettings: resource.uiSettings || {},
          priority: resource.priority,
          public: resource.public,
          useParentUserGroups: resource.useParentUserGroups,
          name: resource.name,
          shortName: resource.shortName,
          backgroundImage: resource.backgroundImage,
          backgroundImageFilename: resource.backgroundImageFilename,
          remark: resource.remark,
          description: resource.description
        })
        if (resource.uiSettings && Object.keys(resource.uiSettings).includes('graphSteps'))
          setShowDataGraphSettings(true)
      }
      setErrors({})
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    let state = {...formState, [name]: value}
    if (name === 'instrumentType'){
      state['group'] = (value === 'group')
    }
    setFormState(state)
  }

  const handleExcludedDataAttributesChange = (ev) => {
    const name = ev.target.name
    const isSelected = !ev.target.checked
    let attr = formState.excludedDataAttributes ? [...formState.excludedDataAttributes] : []
    if (isSelected && attr.indexOf(name) === -1)
      attr.push(name)
    else if (!isSelected && attr.indexOf(name) !== -1)
      attr = attr.filter(a => a !== name)
    setFormState({...formState, excludedDataAttributes: attr})
  }

  const handleUISettingsChange = (ev) => {
    const name = ev.target.name
    const value = ev.target.value
    const settings = {...formState.uiSettings, [name]: value}
    const state = {...formState, uiSettings: settings}
    setFormState(state)
  }

  const save = () => {
    const state = Object.assign({}, formState)
    const uiErrors = {}
    if (state.instrumentType === 'group'){
      state.group = true
      state.instrumentType = null
    }
    if (!showDataGraphSettings){
      if (typeof state.uiSettings === 'object') {
        delete(state.uiSettings.graphMin)
        delete(state.uiSettings.graphMax)
        delete(state.uiSettings.graphSteps)
      }
    } else {
      if (isNaN(state.uiSettings.graphMin) || state.uiSettings.graphMin === '')
        uiErrors.graphMin = 'must be a number'
      if (isNaN(state.uiSettings.graphMax) || state.uiSettings.graphMax === '')
        uiErrors.graphMax = 'must be a number'
      if (isNaN(state.uiSettings.graphSteps) || state.uiSettings.graphSteps === '')
        uiErrors.graphSteps = 'must be a number'
      if (!uiErrors.graphMax && !uiErrors.graphMin && Number(state.uiSettings.graphMin) > Number(state.uiSettings.graphMax))
        uiErrors.graphMin = 'must be smaller than max'
      if (!uiErrors.graphSteps && state.uiSettings.graphSteps < 1)
        uiErrors.graphSteps = 'must be greater than zero'
    }
    if (Object.keys(uiErrors).length > 0){
      setErrors(uiErrors)
      return
    }
    resource.assignAttributes(state)
    resource.assignOrganization(organization)
    resource.assignParent(parent)
    resource.assignAttributes({userGroups: selectedUserGroups, tags: selectedTags})
    if (!parent) {
      resource.assignAttributes({useParentUserGroups: false})
    }
    const msg = resource.persisted() ? 'Instrument updated' : 'Instrument created'
    return resource.save().then(() => {
      if (!parent){
        // Must be done like this for some reason to remove parent
        resource.updateParent().then(() => reloadResources(msg))
      } else {
        reloadResources(msg)
      }
      onClose()
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const DataAttributes = () => (
    <Form.Group as={Row}>
      <Form.Label column={true}>Data attributes</Form.Label>
      <Col sm="auto">
        {!resource.persisted() && <i>- Can be selected after save -</i>}
        {resource.persisted() && resource.dataAttributes.length === 0 && <span>-</span>}
        {resource.persisted() && resource.dataAttributes.map(da =>
        <FormCheckbox key={da.key}
                      onChange={handleExcludedDataAttributesChange}
                      checked={!formState.excludedDataAttributes ||
                              formState.excludedDataAttributes.indexOf(da.key) === -1}
                      name={da.key}/>)}
      </Col>
    </Form.Group>
  )

  const UISettings = () => (
    <React.Fragment>
      <Form.Group as={Row}>
        <Form.Label column={true}>Data graph</Form.Label>
        <Col sm="auto">
            <FormCheckbox onChange={() => {setShowDataGraphSettings(!showDataGraphSettings)}}
                          checked={!showDataGraphSettings}
                          name="automatic"/>
        </Col>
      </Form.Group>
      {showDataGraphSettings &&
      <Row className="justify-content-end" style={{marginLeft: 10}}>
        <Col xs>
          <FormInput size="sm" horizontal label="Min" name="graphMin" value={formState.uiSettings.graphMin} error={errors.graphMin} onChange={handleUISettingsChange}/>
        </Col>
        <Col xs>
          <FormInput size="sm" horizontal label="Max" name="graphMax" value={formState.uiSettings.graphMax} error={errors.graphMax} onChange={handleUISettingsChange}/>
        </Col>
        <Col xs>
          <FormInput size="sm" horizontal label="Steps" name="graphSteps" value={formState.uiSettings.graphSteps} error={errors.graphSteps} onChange={handleUISettingsChange}/>
        </Col>
      </Row>}
    </React.Fragment>
  )

  const DataSettings = () => (
    <React.Fragment>
      <FormSelect
        horizontal
        disabled={formState.group}
        name="dataResolution"
        label="Data resolution"
        options={Object.keys(Instrument.dataResolutions).map(k => [k, Instrument.dataResolutions[k]])}
        value={formState.dataResolution}
        error={errors.dataResolution}
        onChange={handleInputChange}/>
      {resource && UISettings()}
      {resource && DataAttributes()}
    </React.Fragment>
  )

  const setBackgroundFile = (event) => {
    if (event === null) {
      setFormState({...formState, backgroundImageFilename: null, backgroundImage: null})
      return
    }
    setFormState({...formState, backgroundImageFilename: event.target.files[0].name})
    const reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = function () {
      setFormState(state => ({...state, backgroundImage: reader.result}))
    }
    reader.onerror = function (error) {
      setErrors({...errors, backgroundImage: reader.result})
      setFormState(state => ({...state, backgroundImage: null, backgroundImageName: null}))
    }
  }

  const BackgroundSelector = () => (
    <React.Fragment>
      <FormFile
        horizontal
        onChange={setBackgroundFile}
        name="backgroundImage"
        label="Background image"
        error={errors.backgroundImage}
        placeholder={formState.backgroundImageFilename || '- no background -'}
        fileSelected={!!formState.backgroundImageFilename}
      />
    </React.Fragment>
  )

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} resource={resource} label="instrument">
      <Form>
        <InstrumentModalParent resource={resource}
                               horizontal
                               errors={errors}
                               organization={organization}
                               setOrganization={setOrganization}
                               parent={parent}
                               disableOrganization={true}
                               disableParent={!resource || !resource.persisted()}
                               setParent={setParent}/>
        <FormInput horizontal name="name" value={formState.name} error={errors.name} onChange={handleInputChange}/>
        <FormSelect
          horizontal
          disabled={resource && resource.persisted()}
          name="instrumentType"
          label="Type"
          options={[['group', '- group -']].concat(Instrument.instrumentTypes.map(t => [t, Instrument.typeLabel(t)]))}
          blank="- choose -"
          value={formState.instrumentType}
          error={errors.instrumentType}
          onChange={handleInputChange}/>
        {formState.instrumentType && formState.instrumentType !== 'group' &&
        DataSettings()}
        {formState.instrumentType === 'group' &&
        BackgroundSelector()}
        <Row className="justify-content-end">
          <Col md="auto">
            <FormCheckbox name="priority"
                          disabled={formState.group}
                          checked={formState.priority || false}
                          onChange={handleInputChange}/>
          </Col>
          <Col md="auto">
            <FormCheckbox name="public"
                          checked={formState.public || false}
                          onChange={handleInputChange}/>
          </Col>
        </Row>
        <FormInput
          horizontal
          name="shortName"
          value={formState.shortName}
          error={errors.shortName}
          onChange={handleInputChange}
          placeholder="optional short name"
        />
        <FormInput
          placeholder="optional, visible in dashboard"
          horizontal
          rows={2}
          name="description"
          value={formState.description}
          error={errors.description}
          onChange={handleInputChange}
        />
        <FormInput
          placeholder="optional, not visible in dashboard"
          horizontal
          rows={2}
          name="remark"
          value={formState.remark}
          error={errors.remark}
          onChange={handleInputChange}
        />
      </Form>
      {resource && organization &&
      <React.Fragment>
        <h5>User groups</h5>
        <FormCheckbox name="useParentUserGroups"
                      disabled={!parent}
                      checked={formState.useParentUserGroups}
                      onChange={handleInputChange}/>
        {!formState.useParentUserGroups &&
        <UserGroupsSelector resource={resource}
                            organization={organization}
                            selectedUserGroups={selectedUserGroups}
                            setSelectedUserGroups={setSelectedUserGroups}/>}
        <h5>Tags</h5>
        <InstrumentModalTags resource={resource}
                             selectedTags={selectedTags}
                             setSelectedTags={setSelectedTags}/>
      </React.Fragment>}
    </ResourceModal>
  )
}

export default InstrumentModal
