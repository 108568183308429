import React from 'react'
import {Form, Row, Col} from "react-bootstrap"
import uncamelize from "./uncamelize"

const FormGroup = ({name, label, horizontal, children, className, icon}) => {
  const inputSize = () => {
    return horizontal === true ? 8 : horizontal
  }

  const labelProps = () => {
    return horizontal ? {column: true, sm: !isNaN(horizontal) ? 12 - inputSize() : horizontal} : {}
  }

  const formLabel = () => {
    if (label === null || label === undefined)
      return uncamelize(name)
    else if (label !== '')
      return label
    else
      return null
  }

  return (
    <Form.Group as={horizontal ? Row : undefined} controlId={name} className={className}>
      {formLabel() &&
      <Form.Label {...labelProps()}>
        {icon}{formLabel()}
      </Form.Label>}
      {horizontal &&
      <Col sm={inputSize()}>
        {children}
      </Col>}
      {!horizontal && children}
    </Form.Group>
  )
}

export default FormGroup
