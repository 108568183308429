import {ResponsiveContainer, ComposedChart, XAxis, YAxis, CartesianGrid, Area, Tooltip, Line, ReferenceLine, Label} from "recharts"
import React, {useEffect, useRef, useState} from "react"
import {ListGroup, Button, Container, Row, Col, Form} from "react-bootstrap"
import Time from '../lib/Time'
import moment from 'moment-timezone'
import styles from '../styles/InstrumentDataChart.module.css'
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import Instrument from "../resources/Instrument"
import InstrumentDatum from "../resources/InstrumentDatum"
import uncamelize from "../lib/uncamelize"

let currentPayload = null

const InstrumentDataChart = ({instrument, data, width, height, resolution, span, date, unitPostfix, totals, alarmLimits}) => {
  // We have to use useRef to be able to fetch latest state in event listener!
  const [picks, _setPicks] = useState([])
  const [excludedAttributes, setExcludedAttributes] = useState([])
  const picksRef = useRef(picks)

  const setPicks = (p) => {
    picksRef.current = p
    _setPicks(p)
  }

  useEffect(() => {
    document.addEventListener("keydown", pickPayload, false)
    return () => {
      document.removeEventListener("keydown", pickPayload)
    }
  }, [])

  const pickPayload = (event) => {
    if (currentPayload && event.keyCode === 32 && !picksRef.current.find(p => p.time === currentPayload.time))
      setPicks(picksRef.current.concat(currentPayload))
  }

  const formatDate = (utcDate) => {
    //return new Date(utcDate).toUTCString();
    if (resolution === 'hour')
      return Time.formatTime(utcDate, null, 'minutes')
    else if (resolution === 'day')
      return Time.formatTime(utcDate, 'monthAndDay', null)
  }

  const ticks = () => {
    let time = moment(date)
    if (resolution === 'hour'){
      time = time.endOf('hour').add(1, 'hour').add(1, 'minute')
      return [...Array(span + 1).keys()].map(i => time.subtract(1, 'hours').valueOf()).reverse()
    } else if (resolution === 'day'){
      //time = time.endOf('day').add(1, 'day')
      time = time.startOf('day').add(2, 'days')
      return [...Array(span + 1).keys()].map(i => time.subtract(1, 'days').valueOf()).reverse()
    }
    return []
  }

  const handleMouseMove = (obj) => {
    if (obj && Array.isArray(obj.activePayload) && obj.activePayload[0].payload){
      currentPayload = obj.activePayload[0].payload
    } else {
      currentPayload = null
    }
  }

  const removePick = (time) => {
    setPicks(picks.filter(p => p.time !== time))
  }

  const dataKeys = () => {
    if (data.length > 0 && data[0].values)
      return data[0].values.map(v => v.key)
    else
      return []
  }

  const color = (attr = null) => {
    if (attr === null && dataKeys().length > 0)
      return Instrument.color(dataKeys()[0])
    else if (attr === null)
      return "#000"
    else
      return Instrument.color(attr);
  }

  const CustomTooltip = ({active, payload, label}) => {
    if (!active)
      return null
    return (
      <div className={styles.tooltip}>
        <span className={styles.tooltipTitle}><Time>{label}</Time></span>
        <table>
          <tbody>
          {payload && payload.map(p => {
            const key = p.name
            const values = p.payload.values
            const obj = values.find(v => v.key === key)
            return(
              <tr key={p.name} className={styles.tooltipItem} style={{color: p.stroke}}>
                <td>
                  <span className={styles.tooltipItemLabel}>{obj.label}:</span>
                </td>
                <td>
                  <span className={styles.tooltipItemValue}>
                    {Instrument.formattedValue(obj.value, obj.unit, key).value}
                  </span>
                  <span className={styles.tooltipItemUnit}>
                    {Instrument.formattedValue(obj.value, obj.unit, key).unit}
                    {key && key.substr(-4, 4) !== '_min' && key.substr(-4, 4) !== '_max' && unitPostfix}
                  </span>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    )
  }

  const pickedValues = (pick) => {
    const values = pick.values.filter(v => excludedAttributes.indexOf(v.key) === -1)
    return values.map((v, index) => <span className={styles.pickContainer} key={index}>
      <span className={styles.pickLabel} style={{color: Instrument.color(v.key)}}>{v.label}</span>
      <span className={styles.pickValue} style={{color: Instrument.color(v.key)}}>{v.value}</span>
      <span className={styles.pickUnit} style={{color: Instrument.color(v.key)}}>
        {v.unit}
        {v.key && v.key.substr(-4, 4) !== '_min' && v.key.substr(-4, 4) !== '_max' && unitPostfix}
      </span>
      {index < values.length - 1 &&
      <span className={styles.pickSeparator}></span>}
    </span>)
  }

  const handleExcludedAttributeChange = (ev) => {
    const name = ev.target.name
    const isSelected = !ev.target.checked
    let attr = excludedAttributes ? [...excludedAttributes] : []
    if (isSelected && attr.indexOf(name) === -1)
      attr.push(name)
    else if (!isSelected && attr.indexOf(name) !== -1)
      attr = attr.filter(a => a !== name)
    setExcludedAttributes(attr)
  }

  const Totals = () => (
    (totals || []).filter(t => excludedAttributes.indexOf(t.key) === -1).map(total => {
      return (
        <div key={total.key} className={styles.totalBadge} style={{backgroundColor: Instrument.color(total.key)}}>
          <span className={styles.totalBadgeLabel}>{total.label}</span>
          {total.unit !== 'sec' && <React.Fragment>
            <span className={styles.totalBadgeValue}>{Math.round(total.value)}</span>
            <span className={styles.totalBadgeUnit}>{total.unit}</span>
          </React.Fragment>}
          {total.unit === 'sec' && Time.duration(Math.round(total.value) * 1000).map((t, i) =>
          <span key={i} style={{marginRight: 4}}>
            <span className={styles.totalBadgeValue}>{t[0]}</span>
            <span className={styles.totalBadgeUnit}>{t[1]}</span>
          </span>)}
        </div>
      )
    })
  )

  const PickReferenceLines = () => (
    picks.sort((a, b) => a.time > b.time).map((p, i) => (
      <ReferenceLine x={p.time}
                     key={`${i}PickRefLine`}
                     stroke="#555"
                     strokeDasharray="5 2">
        <Label value={`${i + 1}.`}
               position="top"
               fill="#555"
               fontSize={11}/>
      </ReferenceLine>
    ))
  )

  const AlarmReferenceLines = () => (
    Object.keys(alarmLimits).filter(k => !excludedAttributes.includes(uncamelize(k, false, '_'))).map(key =>
      [alarmLimits[key].max !== null &&
      <ReferenceLine y={parseFloat(alarmLimits[key].max)}
                     key={`${key}RefLinesMax`}
                     stroke={Instrument.color(uncamelize(key, false, '_'))}
                     strokeDasharray="5 3">
        <Label value={`Alarm max: ${alarmLimits[key].max}`}
               position="insideRight"
               fill={Instrument.color(uncamelize(key, false, '_'))}
               fontSize={11}
               dy={-8}/>
      </ReferenceLine>,
      alarmLimits[key].min !== null &&
      <ReferenceLine y={parseFloat(alarmLimits[key].min)}
                     key={`${key}RefLinesMin`}
                     stroke={Instrument.color(uncamelize(key, false, '_'))}
                     strokeDasharray="5 3">
        <Label value={`Alarm min: ${alarmLimits[key].min}`}
               position="insideRight"
               fill={Instrument.color(uncamelize(key, false, '_'))}
               fontSize={11}
               dy={-8}/>
      </ReferenceLine>]
    )
  )

  const AttributeSelector = () => (
    <Container fluid className={styles.attributeSelector}>
      <Row>
        {dataKeys().length > 1 && data[0].values.map(v => <Col className={styles.attributeSelectorItem}
                                                               xs="auto"
                                                               key={v.key}
                                                               style={{color: color(v.key)}}>
          <Form.Label htmlFor={`selector-${v.key}`}>
            {v.label}
          </Form.Label>
          <Form.Check name={v.key}
                      id={`selector-${v.key}`}
                      className={styles.attributeSelectorCheckbox}
                      type="checkbox"
                      checked={excludedAttributes.indexOf(v.key) === -1}
                      onChange={handleExcludedAttributeChange}/>
        </Col>)}
      </Row>
    </Container>
  )

  const PickedValues = () => (
    <ListGroup>
      {picks.sort((a, b) => a.time > b.time).map((p, i) => <ListGroup.Item key={p.time}>
        <span className={styles.pickIndex}>{i + 1}.</span>
        <Time className={styles.pickTime}>{p.time}</Time>
        {pickedValues(p)}
        <Button variant="link" className={styles.pickRemove} onClick={() => removePick(p.time)}>
          <FontAwesomeIcon icon={faTimes}/>
        </Button>
      </ListGroup.Item>)}
    </ListGroup>
  )

  // Y axis steps
  const domain = () => {
    if (instrument.uiSettings &&
      instrument.uiSettings.graphMin &&
      instrument.uiSettings.graphMax &&
      instrument.uiSettings.graphSteps)
      return [Number(instrument.uiSettings.graphMin), Number(instrument.uiSettings.graphMax)]
    return InstrumentDatum.chartOptions(dataKeys()[0], instrument.instrumentType).yAxisDomain || [0, 'auto']
  }

  return (
    <React.Fragment>
      {Totals().length > 0 &&
      <span className={styles.totals}>Totals: {Totals()}</span>}
      <ResponsiveContainer width={width || '100%'} height={height || '100%'} className={styles.container}>
        <ComposedChart onMouseMove={handleMouseMove} baseValue="dataMin" data={data} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
          <defs>
            <linearGradient id="grad1" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={color()} stopOpacity={0.7}/>
              <stop offset="95%" stopColor={color()} stopOpacity={0}/>
            </linearGradient>
          </defs>
          <Tooltip content={<CustomTooltip/>}></Tooltip>
          <XAxis dataKey="time"
                 allowDataOverflow={true}
                 tickLine={false}
                 minTickGap={2}
                 tick={{fontSize: "0.7rem"}}
                 name="Time"
                 tickFormatter={formatDate}
                 scale="utc"
                 domain={[ticks()[0], ticks().splice(-1,1)[0]]}
                 ticks={ticks()}
                 type="number"/>
          <YAxis name="Value"
                 domain={domain()}
                 tickCount={instrument.uiSettings && (Number(instrument.uiSettings.graphSteps) || 4) + 1}
                 allowDataOverflow={true}
                 width={50}
                 tick={{fontSize: "0.7rem"}}/>
          <CartesianGrid strokeDasharray="3 3"/>
          {dataKeys().length === 1 &&
          <Area type="monotone"
                name={dataKeys()[0]}
                dataKey={d => d.values.filter(v => v.key === dataKeys()[0])[0].value}
                stroke={color()}
                fillOpacity={1}
                fill="url(#grad1)"/>}
          {dataKeys().length > 1 && dataKeys().filter(k => excludedAttributes.indexOf(k) === -1).map(k =>
          <Line type="monotone"
                key={k}
                name={k}
                dataKey={d => d.values.filter(v => v.key === k)[0].value}
                stroke={color(k)}
                dot={false}/>)}
          {AlarmReferenceLines()}
          {PickReferenceLines()}
        </ComposedChart>
      </ResponsiveContainer>
      {dataKeys().length > 1 && AttributeSelector()}
      {PickedValues()}
    </React.Fragment>
  )
}

export default InstrumentDataChart
