import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import FormSelect from '../lib/FormSelect'
import Organization from "../resources/Organization"
import Device from "../resources/Device"
import FormTypeahead from "../lib/FormTypeahead"
import FormInput from "../lib/FormInput"
import withResourceModal from "../hocs/withResourceModal"
import FormDateTimePicker from "../lib/FormDateTimePicker"

const OrganizationDeviceModal = ({formState, errors, handleInputChange, resource}) => {
  const [ organizations, setOrganizations ] = useState([])
  const [ devices, setDevices ] = useState([])

  useEffect(
    () => {
      Organization.perPage(1000).all().then(orgs => setOrganizations(orgs.toArray()))
      loadDevices({})
    },
    []
  )

  const loadDevices = (search) => {
    return Device.where({search}).order({id: 'asc'}).perPage(100).all().then(res => setDevices(res.toArray()))
  }

  return (
    <Form>
      <FormSelect
        disabled={resource && !resource.persisted()}
        name="organization"
        label="Organization"
        horizontal
        options={organizations.map(org => [org.id, org.name])}
        value={formState.organization && formState.organization.id}
        error={errors.organization}
        onChange={handleInputChange}/>
      <FormTypeahead name="name"
                     horizontal
                     label="ID"
                     options={devices.map(d => d.id)}
                     value={formState.name}
                     onInputChange={loadDevices}
                     error={errors['name']}
                     onChange={handleInputChange}/>
      <FormInput name="appKey" value={formState.appKey} error={errors.appKey} onChange={handleInputChange} horizontal/>
      <FormInput name="appEui" value={formState.appEui} error={errors.appEui} onChange={handleInputChange} horizontal/>
      <FormDateTimePicker name="validFrom"
                          horizontal
                          value={formState.validFrom}
                          error={errors.validFrom}
                          onChange={handleInputChange}/>
      <FormInput name="remark" value={formState.remark} error={errors.remark} onChange={handleInputChange}/>
    </Form>
  )
}

export default withResourceModal(OrganizationDeviceModal, {
  updateTitle: 'Update device',
  createTitle: 'Add device',
  createSubmitLabel: 'Add'
})
