import React, {useState} from 'react'
import {Table, ButtonToolbar} from 'react-bootstrap'
import { Automation } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import ResourceCreateButton from "../components/ResourceCreateButton"
import AutomationModal from "../components/AutomationModal"
import ResourceDisableButton from "../components/ResourceDisableButton"

const AutomationIndex = ({organization}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(Automation.build({
      organization: organization,
      conditions: [['']],
      consequences: [['']]
    }))
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const baseQuery = () => {
    if (organization)
      return organization.automations().includes('organization')
    else
      return Automation.includes('organization')
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="automations">
          New automation
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
          <tr>
            {!organization &&
            <th>Organization</th>}
            <ResourceListSortTh name="name"/>
            <th>Status</th>
            <th className="actions"></th>
          </tr>
        </thead>
        <tbody>
          <ResourceList>
            {resource => <tr key={resource.id} className={`clickable ${resource.disabledAt ? 'disabled' : ''}`}>
              {!organization &&
              <td>{resource.organization().name}</td>}
              <td>{resource.name}</td>
              <td>{resource.disabledAt ? 'Stopped' : 'Running'}</td>
              <td className="actions">
                <ResourceEditButton resource={resource} onClick={() => editResource(resource)} />
                <ResourceDisableButton resource={resource} />
                <ResourceDeleteButton resource={resource} />
              </td>
            </tr>}
          </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <AutomationModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default AutomationIndex
