import React from "react"
import {EventLog, User} from "../Resources"
import ResourceContext from "../contexts/ResourceContext"
import {Button, Col, Form, InputGroup, Row, Table} from "react-bootstrap"
import ResourceList from "../components/ResourceList"
import ResourceListPagination from "../components/ResourceListPagination"
import Time from "../lib/Time"
import ExpandableList from '../lib/ExpandableList'
import ResourceTypeahead from '../components/ResourceTypeahead'
import ResourceFilter from '../components/ResourceFilter'
import FormSelect from '../lib/FormSelect'
import DateTime from 'react-datetime'
import moment from 'moment-timezone'
import classes from '../styles/EventLogIndex.module.css'
import FormGroup from '../lib/FormGroup'

const EVENT_OPTIONS = ['login', 'logout', 'password_reset', 'create', 'update', 'delete']

const EventLogIndex = () => {
  const details = (rawDetails) => {
    let parts = []
    if (rawDetails.model !== undefined) {
      parts.push(`${rawDetails.model} #${rawDetails.id}`)
    } else {
      parts.push(rawDetails.email)
    }
    const info = JSON.stringify(rawDetails, null, 2)
      .replaceAll(',', '')
      .split('\n')
      .filter(i => i !== '{' && i !== '}')
    parts = [...parts, ...info]
    return parts.map((p, i) => <div key={i}>{p}</div>)
  }

  const clear = (props) => {
    props.onChange({target: {value: ''}})
  }

  const renderDateTimeInput = ( props ) => (
    <InputGroup>
      <Form.Control {...props} readOnly />
      <InputGroup.Prepend>
        <Button variant="outline-secondary" onClick={() => clear(props)}>×</Button>
      </InputGroup.Prepend>
    </InputGroup>
  )

  const filter = (filter, handleFilterChange) => (
    <Form>
      <Row className="justify-content-md-left">
        <Col md={4}>
          <ResourceTypeahead
            name="_user.name"
            label="User"
            placeholder="- select -"
            horizontal={10}
            clearButton
            value={
              filter['user.id']
                ? {
                  id: filter['user.id'],
                  name: filter['_user.name'] ?? ''
                }
                : null
            }
            baseQuery={User.order({ name: 'asc' })}
            labelResolver={(res) => res.name}
            onChange={(res) => {
              handleFilterChange(
                { target: { name: '_user.name', value: res?.name ?? undefined} },
                {'user.id': res?.id ?? undefined}
              )
            }}
          />
        </Col>
        <Col md={3}>
          <FormSelect
            horizontal={9}
            blank='- select -'
            name="event"
            label="Event"
            value={filter['event']}
            onChange={handleFilterChange}
            options={EVENT_OPTIONS}
          />
        </Col>
        <Col md={5}>
          <FormGroup label='Time' name='time' horizontal={10}>
            <DateTime name="from"
                      renderInput={renderDateTimeInput}
                      onChange={val => handleFilterChange({
                        target: {
                          name: 'from',
                          value: typeof val === 'object' ? val.format() : val
                        }
                      })}
                      value={filter['from'] ? moment(filter['from']) : ''}
                      viewDate={filter['from']}
                      className={classes.datePicker}
                      closeOnSelect={false}
                      timeFormat="HH:mm"
                      dateFormat="MMM Do YYYY"/>
            <span>&nbsp;-&nbsp;</span>
            <DateTime name="to"
                      renderInput={renderDateTimeInput}
                      onChange={val => handleFilterChange({
                        target: {
                          name: 'to',
                          value: typeof val === 'object' ? val.format() : val
                        }
                      })}
                      value={filter['to'] ? moment(filter['to']) : ''}
                      className={classes.datePicker}
                      viewDate={filter['to']}
                      closeOnSelect={false}
                      timeFormat={"HH:mm"}
                      dateFormat="MMM Do YYYY"/>
            </FormGroup>
        </Col>
      </Row>
    </Form>
  )
  
  return (
    <ResourceContext baseQuery={EventLog.includes('user')} defaultSort={{createdAt: 'desc'}}>
      <ResourceFilter>
        {filter}
      </ResourceFilter>
      <Table striped bordered hover responsive>
        <thead>
        <tr>
          <th>User</th>
          <th>Event</th>
          <th>Details</th>
          <th>Created at</th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id} className={'clickable'}>
            <td>{resource.user().name}</td>
            <td>{resource.event}</td>
            <td>
              <ExpandableList>
               {details(resource.details)}
              </ExpandableList>
            </td>
            <td><Time>{resource.createdAt}</Time></td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
    </ResourceContext>
  )
}

export default EventLogIndex
