import useAuthPopUp from './useAuthPopUp'

const useEntraLogin = (props) => {
  const { openSignInWindow } = useAuthPopUp()

  const url = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
    `client_id=${process.env.REACT_APP_ENTRA_CLIENT_ID}` +
    '&response_type=code' +
    `&redirect_uri=${encodeURIComponent(process.env.REACT_APP_ENTRA_REDIRECT_URL)}` +
    '&response_mode=query' +
    `&scope=${encodeURIComponent('email profile openid')}` +
    '&state=12345'

  const handleMessage = (event) => {
    const { data } = event;
    const params = new URLSearchParams(data)
    if (typeof params.get('code') === 'string') {
      props.onSuccess({ code: params.get('code'), state: params.get('state') })
    } else {
      props.onError()
    }
  }

  return () => { openSignInWindow(url, 'Entra Sign In', handleMessage) }
}

export default useEntraLogin
