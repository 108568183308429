import React from 'react'
import {Form} from "react-bootstrap"
import FormGroup from './FormGroup'

const FormInput = ({name, label, type, value, onChange, error, horizontal, placeholder, rows, size, disabled}) => {
  return (
    <FormGroup name={name} label={label} horizontal={horizontal} size={size}>
      <Form.Control type={type || 'input'}
                    disabled={disabled}
                    placeholder={placeholder}
                    as={rows && rows > 1 ? 'textarea' : 'input'}
                    rows={rows}
                    name={name}
                    size={size}
                    value={value !== null && value !== undefined ? value : ''}
                    onChange={onChange}
                    className={error ? 'is-invalid' : ''}/>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </FormGroup>
  )
}

export default FormInput
