import React, {useContext, useEffect, useState} from 'react'
import { Form } from 'react-bootstrap'
import ResourceModal from "./ResourceModal"
import { RootContext } from "../contexts/RootContext"
import InstrumentAlarmModalReceivers from "./InstrumentAlarmModalReceivers"
import InstrumentAlarmModalGeofence from "./InstrumentAlarmModalGeofence"
import InstrumentAlarmModalTimeSettings from "./InstrumentAlarmModalTimeSettings"
import InstrumentAlarmModalRanges from "./InstrumentAlarmModalRanges"
import styles from '../styles/InstrumentAlarmModal.module.css'
import InstrumentAlarmModalCommands from "./InstrumentAlarmModalCommands"

const InstrumentAlarmModal = ({resource, show, onClose}) => {
  const [ rangesAreValid, setRangesAreValid ] = useState(true)
  const [ formState, setFormState ] = useState({})
  const [ errors, setErrors ] = useState({})
  const [ receivers, setReceivers ] = useState([])
  const [ geofence, setGeofence ] = useState(false)
  const [ commands, setCommands ] = useState([])
  const { showMessage } = useContext(RootContext)

  useEffect(
    () => {
      if (resource){
        let location = null
        if (resource.geofenceLatitude !== null &&
          resource.geofenceLongitude !== null &&
          resource.geofenceLatitude !== undefined &&
          resource.geofenceLongitude !== undefined)
          location = {lat: resource.geofenceLatitude, lon: resource.geofenceLongitude}
        else if (resource.instrument() && resource.instrument().latestLocation())
          location = resource.instrument().latestLocation()
        let triggers = [...(resource.offRangeTriggers || []).map(t => Object.assign({}, t))]
        if (triggers.length === 0)
          triggers = [{attribute: null, min: null, max: null}]
        setFormState({
          offRangeTriggers: triggers,
          dataAgeThresholdMins: resource.dataAgeThresholdMins,
          offRangeThresholdMins: resource.offRangeThresholdMins,
          minEventIntervalMins: resource.minEventIntervalMins,
          stallingThresholdMins: resource.stallingThresholdMins,
          geofenceRadiusM: resource.geofenceRadiusM || 10000,
          geofenceLatitude: location && location.lat,
          geofenceLongitude: location && location.lon
        })
        setReceivers(resource.receivers().toArray())
        setCommands(resource.instrumentAlarmCommands().toArray())
        if (resource.persisted() && resource.geofenceRadiusM !== null)
          setGeofence(true)
      } else {
        setGeofence(false)
        setFormState({})
        setReceivers([])
        setCommands([])
      }
      setErrors({})
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    const value = ev.target.value
    setFormState({...formState, [name]: value})
  }

  const handleCommands = async () => {
    // Delete some commands?
    for (const command of resource.instrumentAlarmCommands().toArray()) {
      if (!commands.find(cmd => cmd.id === command.id)) {
        await command.destroy()
      }
    }
    // Upsert all commands
    for (const command of commands) {
      await command.save()
    }
    // Reload InstrumentAlarm resource command stuff
    await resource.instrumentAlarmCommands().reload()
    for (const command of resource.instrumentAlarmCommands().toArray()) {
      await command.loadCommand()
    }
  }

  const save = async () => {
    const state = {...formState, offRangeTriggers: formState.offRangeTriggers.filter(t => t.attribute)}
    if (state.stallingThresholdMins === '0' || state.stallingThresholdMins === 0) {
      state.stallingThresholdMins = null
    }
    if (state.dataAgeThresholdMins === '0' || state.dataAgeThresholdMins === 0) {
      state.dataAgeThresholdMins = null
    }
    resource.assignAttributes(state)
    resource.assignAttributes({ receivers })
    if (!geofence){
      resource.assignAttributes({geofenceRadiusM: null, geofenceLatitude: null, geofenceLongitude: null})
    }
    const msg = resource.persisted() ? 'Alarm updated' : 'Alarm set'
    try {
      await resource.save()
      await handleCommands()
      showMessage(msg)
      onClose()
    } catch (_error) {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    }
  }

  return (
    <ResourceModal className={styles.modalDialog} show={show} onClose={onClose} onSubmit={save} resource={resource} label="alarm" invalid={!rangesAreValid}>
      {resource &&
      <Form>
        <h6>{resource.instrument().fullName}</h6>
        <hr/>
        {resource.instrument().dataAttributes && resource.instrument().dataAttributes.length > 0 &&
        <InstrumentAlarmModalRanges formState={formState}
                                    setFormState={setFormState}
                                    resource={resource}
                                    setRangesAreValid={setRangesAreValid}/>}
        <InstrumentAlarmModalTimeSettings formState={formState}
                                          resource={resource}
                                          errors={errors}
                                          handleInputChange={handleInputChange}/>
        <InstrumentAlarmModalGeofence formState={formState}
                                      setFormState={setFormState}
                                      geofence={geofence}
                                      setGeofence={setGeofence}
                                      onInputChange={handleInputChange}
                                      errors={errors}/>
        <InstrumentAlarmModalCommands resource={resource}
                                      commands={commands}
                                      setCommands={setCommands} />
      </Form>}
      <h5>Receivers</h5>
      <InstrumentAlarmModalReceivers receivers={receivers} setReceivers={setReceivers}/>
    </ResourceModal>
  )
}

export default InstrumentAlarmModal
