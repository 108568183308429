import React, {useContext, useEffect, useState} from 'react'
import { Form, Row, Col, Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import { ResourceContext } from "../contexts/ResourceContext"
import FormInput from '../lib/FormInput'
import ResourceModal from "./ResourceModal"
import FormCheckbox from "../lib/FormCheckbox"
import InstrumentModalUserGroups from "./UserGroupsSelector"
import InstrumentModalParent from "./InstrumentModalParent"
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import FormDateTimePicker from "../lib/FormDateTimePicker"

const InstrumentCloneModal = ({resource, show, onClose}) => {
  const [ formState, setFormState ] = useState({})
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})
  const [ selectedUserGroups, setSelectedUserGroups ] = useState([])
  const [ organization, setOrganization ] = useState(null)
  const [ parent, setParent ] = useState(null)

  useEffect(
    () => {
      if (resource){
        setOrganization(resource.organization())
        setParent(resource.parent())
        setFormState({
          name: resource.name,
          calibrate: resource.calibrate,
          cloneAlarms: resource.cloneAlarms,
          cloneTags: resource.cloneTags,
          calibrationValidFrom: resource.calibrationValidFrom,
          sourceOfflineFrom: resource.sourceOfflineFrom
        })
      }
      setSelectedUserGroups([])
      setErrors({})
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    setFormState({...formState, [name]: value})
  }

  const save = () => {
    const state = Object.assign({}, formState)
    delete(state.organizationId)
    delete(state.parentId)
    resource.assignAttributes(state)
    if (resource.sourceOfflineFrom.trim() === '')
      resource.sourceOfflineFrom = null
    resource.assignOrganization(organization)
    resource.assignParent(parent)
    resource.assignAttributes({userGroups: selectedUserGroups})
    const msg = 'Instrument cloned'
    return resource.save().then(newResource => {
      reloadResources(msg)
      onClose(newResource)
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const userGroupsTooltip = (props) => (
    <Tooltip id="user-groups-tooltip" {...props}>
      Selected user group privileges will be set for the target and all its children!
      Leave selection empty to copy privileges from source as they are.
    </Tooltip>
  )

  const calibrateTooltip = (props) => (
    <Tooltip id="calibrate-tooltip" {...props}>
      Checking "Calibrate" will copy calibrations from source to target and set validity to start from "Target calibration valid from"-date.
      It will also create offline calibrations to source if "Source calibration offline from"-date is set.
      <br/>
      PRO TIP: This option is very helpful when moving sensors to a new location.
    </Tooltip>
  )

  return (
    <ResourceModal show={show} onClose={onClose} onSubmit={save} resource={resource} title="Clone instrument">
      <h5>Source</h5>
      <p>{resource && resource.instrument().fullName}</p>
      <hr/>
      <h5>Target</h5>
      <Form>
        <InstrumentModalParent resource={resource && resource.instrument()}
                               errors={errors}
                               organization={organization}
                               setOrganization={setOrganization}
                               parent={parent}
                               disableOrganization={false}
                               disableParent={false}
                               setParent={setParent}/>
        <FormInput name="name" value={formState.name} error={errors.name} onChange={handleInputChange}/>
        <Row>
          <Col md="auto">
            <FormCheckbox name="cloneTags"
                          checked={formState.cloneTags || false}
                          onChange={handleInputChange}
                          disabled={resource && resource.persisted()}/>
          </Col>
          <Col md="auto">
            <FormCheckbox name="cloneAlarms"
                          checked={formState.cloneAlarms || false}
                          onChange={handleInputChange}/>
          </Col>
          <Col md="auto">
            <FormCheckbox name="calibrate"
                          checked={formState.calibrate || false}
                          onChange={handleInputChange}/>
          </Col>
          <Col md="auto">
            <OverlayTrigger placement="top" overlay={calibrateTooltip}>
              <Button style={{position: 'relative', top: -8}} variant="outline">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        {formState.calibrate &&
        <FormDateTimePicker name="sourceOfflineFrom"
                            label="Source calibration offline from (can be empty)"
                            value={formState.sourceOfflineFrom}
                            error={errors.sourceOfflineFrom}
                            onChange={handleInputChange}/>}
        {formState.calibrate &&
        <FormDateTimePicker name="calibrationValidFrom"
                            label="Target calibration valid from"
                            value={formState.calibrationValidFrom}
                            error={errors.calibrationValidFrom}
                            onChange={handleInputChange}/>}
      </Form>
      {resource && organization &&
      <React.Fragment>
        <h6>
          User groups
          <OverlayTrigger placement="right" overlay={userGroupsTooltip}>
            <Button variant="outline"><FontAwesomeIcon icon={faQuestionCircle} /></Button>
          </OverlayTrigger>
        </h6>
        <InstrumentModalUserGroups resource={resource}
                                   organization={organization}
                                   selectedUserGroups={selectedUserGroups}
                                   setSelectedUserGroups={setSelectedUserGroups}/>
      </React.Fragment>}
    </ResourceModal>
  )
}

export default InstrumentCloneModal
