import React from 'react'
import { BrowserRouter as Router } from "react-router-dom"
import RootContextProvider from "./contexts/RootContext"
import Menu from './components/Menu'
import Header from './components/Header'
import MainContent from './components/MainContent'
//import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/bootstrap.css'
import Reloader from "./lib/Reloader"
import Footer from "./components/Footer"
import ThemeContextProvider from "./contexts/ThemeContext"
import { GoogleOAuthProvider } from "@react-oauth/google"

const App = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH2_CLIENT_ID}>
      <RootContextProvider>
        <ThemeContextProvider>
          <Reloader/>
          <Router>
            <div id="rootContainer">
              <Header/>
              <Menu/>
              <MainContent/>
              <Footer/>
            </div>
          </Router>
        </ThemeContextProvider>
      </RootContextProvider>
    </GoogleOAuthProvider>
  )
}

export default App
