import React, { useState } from 'react'

const useForm = () => {
  const [formState, setFormState] = useState({})

  const initFormState = (resource) => {
    let initialState = {}
    resource.editableAttributes().forEach(attr => initialState[attr] = resource[attr])
    resource.belongsToAssociations().forEach(attr => initialState[attr] = eval(`resource.${attr}()`))
    setFormState(initialState)
  }

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    setFormState({...formState, [name]: value})
  }

  return {
    formState,
    setFormState,
    handleInputChange,
    initFormState
  }
}

export default useForm
