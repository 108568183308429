import React, { useState } from 'react'
import { useHistory } from "react-router-dom"
import { Table, Button, ButtonToolbar } from 'react-bootstrap'
import { Instrument, InstrumentClone } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import InstrumentModal from "../components/InstrumentModal"
import InstrumentCloneModal from "../components/InstrumentCloneModal"
import { faChevronRight, faGlobe, faBell } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ResourceCreateButton from "../components/ResourceCreateButton"
import ExpandableList from "../lib/ExpandableList"
import InstrumentActionsButton from "../components/InstrumentActionsButton"
import InstrumentOfflineModal from "../components/InstrumentOfflineModal"
import InstrumentOnlineModal from "../components/InstrumentOnlineModal"

const InstrumentIndex = ({organization, parent}) => {
  const [showModal, setShowModal] = useState(null)
  const [selectedResource, setSelectedResource] = useState(null)
  const [instrumentClone, setInstrumentClone] = useState(null)
  const history = useHistory()

  const editResource = (resource, event) => {
    if (parent)
      resource.assignParent(parent)
    setSelectedResource(resource)
    setShowModal('instrument')
    event.stopPropagation()
  }

  const newResource = () => {
    const instrument = Instrument.build({
      organization: organization,
      parent: parent,
      dataResolution: Object.keys(Instrument.dataResolutions)[0],
      userGroups: parent && parent.userGroups() && parent.userGroups().toArray(),
      useParentUserGroups: !!parent
    })
    setSelectedResource(instrument)
    setShowModal('instrument')
  }

  const cloneResource = (resource, event) => {
    const clone = InstrumentClone.build({
      instrument: resource,
      organization: organization,
      parent: parent,
      sourceOfflineFrom: new Date().toJSON(),
      calibrationValidFrom: new Date().toJSON(),
      name: resource.name + ' (clone)'
    })
    setInstrumentClone(clone)
    setShowModal('instrumentClone')
    event.stopPropagation()
  }

  const closeModal = () => {
    setSelectedResource(null)
    setInstrumentClone(null)
    setShowModal(null)
  }

  const showResource = (resource) => {
    if (resource.group)
      history.push(`/instruments/${resource.id}/children`)
    else
      history.push(`/instruments/${resource.id}`)
  }

  const offlineResource = (resource, event) => {
    setSelectedResource(resource)
    setShowModal('instrumentOffline')
    event.stopPropagation()
  }

  const onlineResource = (resource, event) => {
    setSelectedResource(resource)
    setShowModal('instrumentOnline')
    event.stopPropagation()
  }

  const baseQuery = () => {
    const includes = [
      'organization', 'userGroups', 'instrumentAlarm', 'tags'
    ]
    if (parent)
      return parent.children().includes(...includes).where({disablingStatus: 'all'})
    else if (organization)
      return organization.instruments().includes(...includes).where({disablingStatus: 'all', root: true})
    else
      return Instrument.includes(...includes).where({disablingStatus: 'all', root: true})
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="instruments">
          New instrument
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover responsive="horizontal">
        <thead>
        <tr>
          {!organization &&
          <th>Organization</th>}
          <ResourceListSortTh name="name"/>
          <ResourceListSortTh name="instrument_type">Type</ResourceListSortTh>
          <th className="hide-for-sm">User groups</th>
          <th className="hide-for-sm">Tags</th>
          <th className="hide-for-sm">Description</th>
          <th className="hide-for-sm">Remark</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr className={`clickable ${resource.disabledAt ? 'disabled' : ''}`}
                           key={resource.id}
                           onClick={() => showResource(resource)}>
            {!organization &&
            <td>{resource.organization && resource.organization() && resource.organization().name}</td>}
            <td>
              {resource.name}
              {resource.public && <FontAwesomeIcon title="Public" className="float-right" icon={faGlobe}/>}
              {resource.instrumentAlarm() && !resource.instrumentAlarm().disabledAt &&
              <FontAwesomeIcon title="Alarm is on" className="float-right" icon={faBell}/>}
            </td>
            <td>{resource.typeLabel()}</td>
            <td className="hide-for-sm">
              <ExpandableList>
                {[resource.useParentUserGroups ? <div>- uses parent privileges -</div> : null,
                  ...resource.userGroups().toArray().sort((a, b) => a.name > b.name ? 1 : -1).map(group => (
                    <div key={group.name}>{group.name}</div>)
                  )]}
              </ExpandableList>
            </td>
            <td className="hide-for-sm">
              <ExpandableList>
                {resource.tags().toArray().sort((a, b) => a.name > b.name ? 1 : -1).map(tag => <div key={tag.name}>{tag.name}</div>)}
              </ExpandableList>
            </td>
            <td className="hide-for-sm">
              <ExpandableList>
                {resource.description && resource.description.split("\n").map(line => <div>{line}</div>)}
              </ExpandableList>
            </td>
            <td className="hide-for-sm">
              <ExpandableList>
                {resource.remark && resource.remark.split("\n").map(line => <div>{line}</div>)}
              </ExpandableList>
            </td>
            <td className="actions">
              <InstrumentActionsButton
                resource={resource}
                onEdit={(event) => editResource(resource, event)}
                onClone={(event) => cloneResource(resource, event)}
                onTakeOffline={(event) => offlineResource(resource, event)}
                onTakeOnline={(event) => onlineResource(resource, event)}
              />
              <ResourceDeleteButton resource={resource}/>
              <Button variant="link">
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <InstrumentModal resource={selectedResource} show={showModal === 'instrument'} onClose={closeModal}/>
      <InstrumentCloneModal resource={instrumentClone} show={showModal === 'instrumentClone'} onClose={closeModal}/>
      <InstrumentOfflineModal resource={selectedResource} show={showModal === 'instrumentOffline'} onClose={closeModal}/>
      <InstrumentOnlineModal resource={selectedResource} show={showModal === 'instrumentOnline'} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default InstrumentIndex
