import React, {useContext, useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import { ResourceContext } from '../contexts/ResourceContext'
import FormInput from '../lib/FormInput'
import ResourceModal from './ResourceModal'
import styles from '../styles/CommandModal.module.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons"

const AutomationModal = ({resource, show, onClose}) => {
  const [ formState, setFormState ] = useState({})
  const [ conditions, setConditions ] = useState([])
  const [ consequences, setConsequences ] = useState([])
  const { reloadResources } = useContext(ResourceContext)
  const [ errors, setErrors ] = useState({})

  useEffect(
    () => {
      if (resource){
        setFormState({
          organization: resource.organization(),
          name: resource.name
        })
        // Rails backend sends conditions and consequences as an array of objects for some mysterious reason
        setConditions(resource.conditions.map(c => Array.isArray(c) ? c.join("\n") : Object.values(c).join("\n")))
        setConsequences(resource.consequences.map(c => Array.isArray(c) ? c.join("\n") : Object.values(c).join("\n")))
      }
      setErrors({})
    },
    [resource]
  )

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    let state = {...formState, [name]: value}
    setFormState(state)
  }

  const handleConditionChange = (value, index) => {
    const newConditions = [...conditions]
    newConditions.splice(index, 1, value)
    setConditions(newConditions)
  }

  const handleConsequenceChange = (value, index) => {
    const newConsequences = [...consequences]
    newConsequences.splice(index, 1, value)
    setConsequences(newConsequences)
  }

  const save = () => {
    const state = Object.assign({}, formState)
    state.conditions = conditions.map(c => c.split("\n")).filter(c => c !== '')
    state.consequences = consequences.map(c => c.split("\n")).filter(c => c !== '')
    resource.assignAttributes(state)
    const msg = resource.persisted() ? 'Automation updated' : 'Automation created'
    return resource.save().then(() => {
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const add = () => {
    const newConditions = [...conditions, '']
    const newConsequences = [...consequences, '']
    setConditions(newConditions)
    setConsequences(newConsequences)
  }

  const remove = (index) => {
    const newConditions = [...conditions]
    const newConsequences = [...consequences]
    newConditions.splice(index, 1)
    newConsequences.splice(index, 1)
    setConditions(newConditions)
    setConsequences(newConsequences)
  }

  return (
    <ResourceModal
      show={show}
      onClose={onClose}
      onSubmit={save}
      resource={resource}
      label="automation"
      className={styles.modalDialog}
    >
      <Form>
        <FormInput
          readOnly
          onChange={(ev) => {}}
          horizontal
          name="organization"
          value={formState.organization && formState.organization.name}/>
        <FormInput
          horizontal
          name="name"
          value={formState.name}
          error={errors.name}
          onChange={handleInputChange}/>
      </Form>
      {conditions.map((condition, index) => (
        <div key={`rule-block-${index}`}>
          <hr style={{clear: 'both'}} />
          <FormInput horizontal
                     rows={Math.max(2, condition.split("\n").length)}
                     name='conditions'
                     value={condition}
                     error={errors.conditions}
                     placeholder='instruments.1.temp > 10'
                     onChange={(ev) => handleConditionChange(ev.target.value, index)}
          />
          <FormInput horizontal
                     rows={Math.max(2, consequences[index].split("\n").length)}
                     name='consequences'
                     value={consequences[index]}
                     error={errors.consequences}
                     placeholder='commands.1.send'
                     onChange={(ev) => handleConsequenceChange(ev.target.value, index)}
          />
          {index > 0 &&
          <Button variant='link-danger' onClick={() => remove(index)} size='sm' className='float-right'>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>}
        </div>
      ))}
      <hr style={{clear: 'both'}} />
      <Button variant="dark" onClick={add} size='sm' className='float-right'>
        <FontAwesomeIcon icon={faPlus} />&nbsp;
        <span>Add</span>
      </Button>
    </ResourceModal>
  )
}

export default AutomationModal
