import React, {useContext, useState} from 'react'
import {Form, Button, ButtonToolbar} from 'react-bootstrap'
import { RootContext } from "../contexts/RootContext"
import styles from '../styles/LoginForm.module.css'
import config from '../config'
import {LinkContainer} from "react-router-bootstrap"

const ForgotPasswordForm = () => {
  const [formState, setFormState] = useState({email: ''})
  const { showMessage } = useContext(RootContext)

  const handleInputChange = (ev) => {
    const name = ev.target.name
    const value = ev.target.value
    setFormState({...formState, [name]: value})
  }

  const resetPassword = () => {
    const fetchConfig = {
      method: 'POST',
      headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
      body: JSON.stringify({user: {email: formState.email}})
    }

    const url = `${config.apiUrl}/auth/password?client=${process.env.REACT_APP_THEME}`

    //console.log(fetchConfig)

    fetch(url, fetchConfig).then(response => response.json().then(user => ({user, response}))).then(
      ({user, response}) => {
        if (!response.ok) {
          console.log("Response not Ok")
          return Promise.reject(response)
        } else {
          showMessage('Password reset instructions sent to given email IF valid')
        }
    }).catch(err => {
      console.error(err)
      showMessage('Network error', 'danger')
    })
  }

  return (
    <Form className={styles.form}>
      <h4>Reset account password</h4>
      <Form.Group controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control autoFocus={true} type="email" name="email" value={formState.email} onChange={handleInputChange}/>
      </Form.Group>
      <ButtonToolbar className="float-right">
        <LinkContainer to="/login">
          <Button variant="secondary">Cancel</Button>
        </LinkContainer>
        &nbsp;
        <Button onClick={resetPassword}>Reset password</Button>
      </ButtonToolbar>
    </Form>
  )
}

export default ForgotPasswordForm
