import React, { useContext } from 'react'
import { Dropdown } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBell } from "@fortawesome/free-solid-svg-icons"
import { RootContext } from "../contexts/RootContext"

const InstrumentAlarmDropdown = ({alarm, setShowModal, disabled, onUpdate}) => {
  const { showMessage } = useContext(RootContext)

  const editAlarm = () => {
    setShowModal(true)
  }

  const disableAlarm = () => {
    alarm.assignAttributes({disabled: true})
    alarm.save().then(() => {
      showMessage('Alarm disabled')
      onUpdate()
    })
  }

  const enableAlarm = () => {
    alarm.assignAttributes({disabled: false})
    alarm.save().then(() => {
      showMessage('Alarm enabled')
      onUpdate()
    })
  }

  const newAlarm = () => {
    setShowModal(true)
  }

  const alarmStatus = () => {
    if (!alarm.persisted())
      return 'No alarm set'
    else if (alarm.disabledAt)
      return 'Alarm disabled'
    else
      return 'Alarm enabled'
  }

  return(
  <Dropdown alignRight={true}>
    <Dropdown.Toggle disabled={disabled}
                     variant="dark"
                     id="dropdown-basic"
                     data-id={alarm.id}
                     className={alarm.isEnabled() ? 'active' : ''}>
      <FontAwesomeIcon icon={faBell} className={alarm.isEnabled() ? 'hilite' : ''}/>&nbsp;&nbsp;
      <span className="hide-for-sm">{alarmStatus()}</span>
    </Dropdown.Toggle>
    <Dropdown.Menu>
      {!alarm.persisted() &&
      <Dropdown.Item onClick={newAlarm}>Set alarm</Dropdown.Item>}
      {alarm.persisted() &&
      <React.Fragment>
        <Dropdown.Item onClick={editAlarm}>Edit alarm</Dropdown.Item>
        {!alarm.disabledAt &&
        <Dropdown.Item onClick={disableAlarm}>Disable alarm</Dropdown.Item>}
        {alarm.disabledAt &&
        <Dropdown.Item onClick={enableAlarm}>Enable alarm</Dropdown.Item>}
      </React.Fragment>}
    </Dropdown.Menu>
  </Dropdown>
  )
}

export default InstrumentAlarmDropdown
