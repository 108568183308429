import React from 'react'
import {Form} from "react-bootstrap"
import DateTime from "react-datetime"
import moment from "moment-timezone"
import FormGroup from "./FormGroup"

const FormDateTimePicker = ({name, label, value, onChange, error, horizontal, timeFormat, dateFormat}) => {
  const handleChange = (momentTime) => {
    let resolvedValue = ''
    if (typeof momentTime === "object")
       resolvedValue = momentTime.format()
    onChange({target: {value: resolvedValue, name: name}})
  }

  const timezone = () => {
    return moment.tz.guess() || 'Europe/Helsinki'
  }

  const resolveTimeFormat = () => {
    if (timeFormat === undefined)
      return "HH:mm:ss"
    else if (timeFormat)
      return timeFormat
    else
      return null
  }

  const resolveDateFormat = () => {
    if (dateFormat === undefined)
      return timeFormat === null ? "MMM Do YYYY" : "MMM Do YYYY,"
    else if (dateFormat)
      return dateFormat
    else
      return null
  }

  const formattedValue = () => {
    if (!value)
      return ''
    return moment(value).tz(timezone()).format([resolveDateFormat(), resolveTimeFormat()].filter(i => i).join(' '))
  }

  return (
    <FormGroup name={name} label={label} horizontal={horizontal}>
      <DateTime name={name}
                onChange={handleChange}
                value={formattedValue()}
                timeFormat={resolveTimeFormat()}
                dateFormat={resolveDateFormat()}
                className={error ? 'is-invalid' : ''}/>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </FormGroup>
  )
}

export default FormDateTimePicker
