import {useRef} from 'react'

const useAuthPopUp = () => {
  const windowObjectRef = useRef(null)
  const previousUrlRef = useRef(null)

  const openSignInWindow = (url, name, onMessage) => {
    // remove any existing event listeners
    window.removeEventListener('message', onMessage)

    // window features
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'

    if (windowObjectRef.current === null || windowObjectRef.current.closed) {
      /* if the pointer to the window object in memory does not exist
       or if such pointer exists but the window was closed */
      windowObjectRef.current = window.open(url, name, strWindowFeatures)
    } else if (previousUrlRef.current !== url) {
      /* if the resource to load is different,
       then we load it in the already opened secondary window and then
       we bring such window back on top/in front of its parent window. */
      windowObjectRef.current = window.open(url, name, strWindowFeatures)
      windowObjectRef.current.focus()
    } else {
      /* else the window reference must exist and the window
       is not closed; therefore, we can bring it back on top of any other
       window with the focus() method. There would be no need to re-create
       the window or to reload the referenced resource. */
      windowObjectRef.current.focus()
    }

    window.addEventListener('message', event => onMessage(event), false)

    // assign the previous URL
    previousUrlRef.current = url
  }

  return { openSignInWindow }
}

export default useAuthPopUp