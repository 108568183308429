import React, {useContext} from 'react'
import styles from "../styles/InstrumentWidget.module.css"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {
  faBatteryQuarter,
  faExclamationTriangle,
  faEyeSlash,
  faHistory,
  faSatelliteDish
} from "@fortawesome/free-solid-svg-icons"
import Instrument from "../resources/Instrument"
import {InstrumentStatusContext} from "../contexts/InstrumentStatusContext"
import {Spinner} from "react-bootstrap"

const InstrumentWidgetRow = ({instrument, className, iconClassName, onClick, valueLabelClassName}) => {
  const { lastUpdateTime } = useContext(InstrumentStatusContext)

  const latestData = (instrument) => {
    return instrument.latestData(true)
  }

  const value = () => {
    if (instrument.isTracker() && instrument.batteryLevel() !== null)
      return <React.Fragment>
        <span>{instrument.batteryLevel()}</span>
        <span className={styles.valueLabelUnit}>%</span>
      </React.Fragment>
    else if(instrument.isTracker() && instrument.batteryLevel() === null && instrument.batteryVoltage() !== null)
      return <React.Fragment>
        <span key={"batteryVoltage"}>
          <span>{instrument.batteryVoltage()}</span><span className={styles.valueLabelUnit}>V</span>
        </span>
      </React.Fragment>
    else
      return latestData(instrument).map((data, index) => {
          const ret = <span key={index + "v"} style={{color: Instrument.color(data.key)}}>
              <span>{data.value}</span><span className={styles.valueLabelUnit}>{data.unit}</span>
            </span>
          if (index < latestData(instrument).length - 1)
            return [ret, <span key={index + "s"} className={styles.valueLabelSeparator}> </span>]
          else
            return ret
        })
  }

  const InstrumentValueBadge = () => {
    if (instrument.group)
      return null
    else
      return <div className={valueLabelClassName || styles.valueLabel}>
        <span style={{opacity: instrument.latestDataIsTooOld() ? 0.5 : 1}}>{value()}</span>
      </div>
  }

  const WarningIcons = () => (
    <React.Fragment>
      {instrument.poorSignal() &&
      <FontAwesomeIcon className={styles.markerLabelPoorSignal} icon={faSatelliteDish} title="Poor signal strength!"/>}
      {instrument.batteryLow() &&
      <FontAwesomeIcon className={styles.markerLabelBatteryWarning} icon={faBatteryQuarter} title="Battery almost empty!"/>}
      {instrument.latestDataIsTooOld() &&
      <FontAwesomeIcon className={styles.markerLabelOldDataWarning} icon={faHistory}  title="Latest data might be outdated!"/>}
      {instrument.alarmIsTriggered() &&
      <FontAwesomeIcon className={styles.markerLabelWarning} icon={faExclamationTriangle} title="Alarm has been triggered!"/>}
    </React.Fragment>
  )

  const IconAndName = () => (
    <React.Fragment>
      <span style={{display: 'none'}}>Refresh timestamp:{lastUpdateTime(instrument.id)}</span>
      <img alt="icon" className={iconClassName || styles.icon} src={instrument.icon()}/>
      <span className={styles.name}>{instrument.name}</span>
    </React.Fragment>
  )

  const isLoading = () => (
    !instrument || !instrument.instrumentLatestData || !instrument.instrumentLatestData()
  )

  const formattedDescription = () => {
    return instrument.description.split("\n").map(line => [...line.split(" ").map(term => {
      if (term.substring(0, 4) === 'http') {
        return <><a href={term} target='_blank'>{term}</a> </>
      } else {
        return `${term} `
      }
    }), <br/>])
  }

  return (
    <div>
      <div className={className}
           key={instrument.id}
           onClick={() => onClick && onClick(instrument)}
           title={instrument.titleInfo()}>
        <div className={styles.iconAndName}>
          {IconAndName()}
        </div>
        {isLoading() && <Spinner animation="border" role="status" className={styles.spinner}>
          <span className="sr-only">Loading...</span>
        </Spinner>}
        {!isLoading() &&
        <div className={styles.warningIconsAndBadge}>
          {!instrument.group && !instrument.isOffline() && WarningIcons()}
          {!instrument.group && instrument.isOffline() &&
          <FontAwesomeIcon className={styles.markerLabelOffline} icon={faEyeSlash} title="Instrument has been taken offline"/>}
          {InstrumentValueBadge()}
        </div>}
      </div>
      {instrument.description &&
      <div className={styles.description}>
        {formattedDescription()}
      </div>}
    </div>
  )
}

export default InstrumentWidgetRow
