import React, { useContext, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { RootContext } from "../contexts/RootContext"
import styles from '../styles/Header.module.css'
import { faUser, faSignOutAlt, faGlobe, faColumns } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AccountModal from "./AccountModal"
import SignOutModal from "./SignOutModal"
import { ThemeContext } from "../contexts/ThemeContext"

const Header = () => {
  const { isAuthenticated, auth, setAuth, showMessage, setDefaultView } = useContext( RootContext )
  const { Header } = useContext(ThemeContext)
  const [ showAccountModal, setShowAccountModal ] = useState( false )
  const [ showSignOutModal, setShowSignOutModal ] = useState( false )
  const history = useHistory()
  const location = useLocation()

  const afterAccountUpdate = (account) => {
    showMessage("Account updated")
    setAuth({...auth, user: Object.assign(auth.user, {name: account.name, email: account.email})})
  }

  const showDashboard = () => {
    setDefaultView('dashboard')
    history.push('/dashboard')
  }

  const showMap = () => {
    setDefaultView('map')
    history.push('/map')
  }

  const mapIsActive = () => {
    return location.pathname.includes('map')
  }

  const dashboardIsActive = () => {
    return location.pathname.includes('dashboard')
  }

  return (
    <Header>
      {isAuthenticated() && <span className={styles.account}>
        <Dropdown alignRight={true}>
          <span className={styles.username}>{auth.user && auth.user.name}</span>
          <Dropdown.Toggle variant="dark" id="dropdown-basic" className={styles.dropdownToggle}>
            <FontAwesomeIcon icon={faUser} />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item disabled={mapIsActive()}
                           onClick={showMap}>
              <FontAwesomeIcon icon={faGlobe} style={{marginRight: 10}}/>
              Map view
            </Dropdown.Item>
            <Dropdown.Item disabled={dashboardIsActive()}
                           onClick={showDashboard}>
              <FontAwesomeIcon icon={faColumns} style={{marginRight: 10}}/>
              Dashboard
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setShowAccountModal(true)}>
              <FontAwesomeIcon icon={faUser} style={{marginRight: 10}}/>
              Edit account
            </Dropdown.Item>
            <Dropdown.Divider/>
            <Dropdown.Item onClick={() => setShowSignOutModal(true)}>
              <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: 10}}/>
              Sign out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <AccountModal show={showAccountModal}
                      userId={auth.user.id}
                      afterSave={afterAccountUpdate}
                      onClose={() => setShowAccountModal(false)}/>
        <SignOutModal show={showSignOutModal}
                      onClose={() => setShowSignOutModal(false)}/>
      </span>}
    </Header>
  )
}

export default Header
