import React, { useContext } from 'react'
import Url from "../lib/Url"
import { ResourceContext } from "../contexts/ResourceContext"
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ResourceListSortTh = ({name, children}) => {
  const {sort, setSort} = useContext(ResourceContext)

  const label = () => {
    const ret = name.replace('_', ' ')
    return ret.charAt(0).toUpperCase() + ret.slice(1)
  }

  const icon = () => (<span className="float-right" style={{marginLeft: '6px'}}>
    {sort[name] === 'asc' &&
    <FontAwesomeIcon icon={faChevronUp} />}
    {sort[name] === 'desc' &&
    <FontAwesomeIcon icon={faChevronDown} />}
  </span>)

  const toggleSort = () => {
    let newSort  //= Object.assign({}, sort)
    /*
    if (newSort[name] === 'asc'){
      newSort[name] = 'desc'
    } else if (newSort[name] === 'desc'){
      newSort[name] = ''
    } else {
      if (newSort[name] === ''){
        delete(newSort[name]) // To reorder the keys
      }
      newSort[name] = 'asc'
    }*/
    if (sort[name] === 'asc') {
      newSort = {[name]: 'desc'}
    } else {
      newSort = {[name]: 'asc'}
    }
    setSort(newSort)
    Url.setQueryParam('sort', newSort)
  }

  return (
    <th onClick={toggleSort} className="sortable">
      {children || label()}
      {icon()}
    </th>
  )
}

export default ResourceListSortTh
