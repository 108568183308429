import React, {useEffect, useState, useContext} from "react"
import Breadcrumbs from "../lib/Breadcrumbs"
import { Instrument } from "../Resources"
import {Link, useParams, useRouteMatch, Switch, Redirect, Route} from 'react-router-dom'
import AuthenticatedRoute from "../components/AuthenticatedRoute"
import InstrumentCalibrationIndex from "./InstrumentCalibrationIndex"
import InstrumentAlarmDetails from './InstrumentAlarmDetails'
import InstrumentIndex from './InstrumentIndex'
import InstrumentInfo from './InstrumentInfo'
import ResourceContext from "../contexts/ResourceContext"
import ResourceList from "../components/ResourceList"
import { RootContext } from "../contexts/RootContext"
import SubMenu from "../components/SubMenu"

const InstrumentDetails = () => {
  const { canIndex } = useContext(RootContext)
  const { instrumentId } = useParams()
  const { path, url } = useRouteMatch()
  const [ queryId, setQueryId ] = useState(null)

  useEffect(
    () => {
      if (instrumentId){
        setQueryId(instrumentId)
      }
    },
    [instrumentId]
  )

  const baseQuery = () => {
    return Instrument.where({id: instrumentId, disablingStatus: 'all'})
      .includes(
        'organization',
        'instrumentLatestData',
        'instrumentAlarm.receivers',
        'instrumentAlarm.instrumentAlarmCommands.command',
        'userGroups',
        'parent',
        'tags'
      )
  }

  return (
    <ResourceContext baseQuery={baseQuery()} id={queryId}>
      <ResourceList>
      {instrument => <React.Fragment key={instrument.id}>
      <Breadcrumbs>
        <Link to="/organizations">
          Organizations
        </Link>
        <Link to={`/organizations/${instrument.organization().id}/instruments`}>
          {instrument.organization().fullName}
        </Link>
        <Link to={`/organizations/${instrument.organization().id}/instruments`}>
          Instruments
        </Link>
        {instrument.ancestors.map(i =>
        <Link to={`/instruments/${i.id}/children`} key={i.id}>
          {i.name}
        </Link>)}
        {instrument.name}
      </Breadcrumbs>
      <SubMenu baseUrl={url} items={[
        {url: '/info', label: 'Info'},
        canIndex('instrumentCalibrations') && {url: '/calibrations', label: 'Calibrations'},
        canIndex('instrumentAlarms') && {url: '/alarm', label: 'Alarm'},
        instrument.group && {url: '/children', label: 'Instruments'}
      ]}/>
      <Switch>
        <Route path={path} exact render={() => <Redirect to={`${url}/info`}/>}/>
        <AuthenticatedRoute path={`${path}/children`}>
          <InstrumentIndex organization={instrument.organization()} parent={instrument}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/calibrations`}>
          <InstrumentCalibrationIndex instrument={instrument}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/alarm`}>
          <InstrumentAlarmDetails instrument={instrument}/>
        </AuthenticatedRoute>
        <AuthenticatedRoute path={`${path}/info`}>
          <InstrumentInfo instrument={instrument}/>
        </AuthenticatedRoute>
      </Switch>
      </React.Fragment>}
      </ResourceList>
    </ResourceContext>
  )
}

export default InstrumentDetails
