import { useState, useEffect, useRef } from 'react'

const useWindowScrollOffsets = () => {
  const [scrollOffset, setScrollOffset] = useState({scrollX: 0, scrollY: 0})
  const timeOutRef = useRef(null)

  const handleResize = () => {
    if (timeOutRef.current)
      window.clearTimeout(timeOutRef.current)
    timeOutRef.current = window.setTimeout(() => {
      setScrollOffset({scrollX: window.pageXOffset, scrollY: window.pageYOffset})
    }, 250)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleResize)
    return () => window.removeEventListener('scroll', handleResize)
  }, [])

  return scrollOffset
}

export default useWindowScrollOffsets
