import React, {useState} from 'react'
import {Button, Modal, Spinner} from "react-bootstrap"

const ResourceModal = ({show, onClose, onSubmit, resource, children, label, title, submitLabel, invalid, className}) => {
  const [loading, setLoading] = useState(false)

  const submit = async () => {
    setLoading(true)
    await onSubmit()
    setLoading(false)
  }

  const close = () => {
    setLoading(false)
    onClose()
  }

  return(
    <Modal show={show} onHide={onClose} backdrop="static" dialogClassName={className}>
      <Modal.Header closeButton={!loading}>
        {title &&
        <Modal.Title>{title}</Modal.Title>}
        {!title && resource && resource.persisted() &&
        <Modal.Title>Edit {label}</Modal.Title>}
        {!title && resource && !resource.persisted() &&
        <Modal.Title>Create {label}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close} disabled={loading}>
          Cancel
        </Button>
        <Button variant="primary" onClick={submit} disabled={loading || invalid}>
          {submitLabel &&
          <span>{submitLabel}</span>}
          {!submitLabel && resource && resource.persisted() &&
          <span>Update</span>}
          {!submitLabel && resource && !resource.persisted() &&
          <span>Create</span>}
          {loading &&
          <Spinner animation="border" role="status" size="sm" style={{marginLeft: 10}}>
            <span className="sr-only">Loading...</span>
          </Spinner>}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResourceModal
