import React, {useState} from 'react'
import {Table, ButtonToolbar} from 'react-bootstrap'
import { DownlinkPreset } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import DownlinkPresetModal from "../components/DownlinkPresetModal"
import ResourceCreateButton from "../components/ResourceCreateButton"

const DownlinkPresetIndex = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(DownlinkPreset.build())
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const baseQuery = () => {
    return DownlinkPreset
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{deviceModel: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="downlinkPresets">
          New downlink preset
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
        <tr>
          <ResourceListSortTh name="deviceModel"/>
          <ResourceListSortTh name="name"/>
          <ResourceListSortTh name="payload"/>
          <ResourceListSortTh name="port"/>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id}>
            <td>{resource.deviceModel}</td>
            <td>{resource.name}</td>
            <td>{resource.payload}</td>
            <td>{resource.port}</td>
            <td className="actions">
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)}/>
              <ResourceDeleteButton resource={resource}/>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <DownlinkPresetModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default DownlinkPresetIndex
