import React, { useState } from 'react'
import {Button, Col, Row} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons"

const ExpandableList = ({children}) => {
  const [ expand, setExpand ] = useState(false)

  const toggleExpand = (event) => {
    setExpand(!expand)
    event.stopPropagation()
  }

  const ToggleButton = () => (
    <Button variant="link" className="float-right" onClick={(event) => toggleExpand(event)}>
      {expand &&
      <span title="less"><FontAwesomeIcon icon={faAngleUp} /></span>}
      {!expand &&
      <span title="more"><FontAwesomeIcon icon={faAngleDown} /></span>}
    </Button>
  )

  const items = () => (
    !children ? [] : children.filter(c => c !== null)
  )

  const rows = () => {
    if (items().length > 1 && !expand)
      return items()[0]
    else
      return items()
  }

  return (
    <Row style={{whiteSpace: "nowrap"}}>
      <Col>
        {rows()}
      </Col>
      <Col>
        {items().length > 1 &&
        ToggleButton()}
      </Col>
    </Row>
  )
}

export default ExpandableList
