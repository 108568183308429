import React, {useEffect, useState} from "react"
import {Button, Form, ListGroup} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import User from "../resources/User"
import FormTypeahead from "../lib/FormTypeahead"

const InstrumentAlarmModalReceivers = ({receivers, setReceivers}) => {
  const [ users, setUsers ] = useState([])

  useEffect(
    () => {
      loadUsers()
    },
    []
  )

  const loadUsers = (search) => {
    const conds = search ? {search} : {}
    User.where(conds).perPage(200).order({name: 'asc'}).all().then(users => setUsers(users.toArray()))
  }

  const handleTypeaheadInput = (value) => {
    loadUsers(value)
  }

  const addReceiver = (ev) => {
    const value = ev.target.value
    const user = users.find(u => parseInt(u.id, 10) === parseInt(value.id, 10))
    setReceivers(receivers.concat(user))
  }

  const removeReceiver = (receiver) => {
    setReceivers(receivers.filter(r => r !== receiver))
  }

  const userOptions = () => {
    const excludeIds = receivers.map(r => parseInt(r.id, 10))
    return users.filter(u => !excludeIds.includes(parseInt(u.id, 10))).map(u => ({id: u.id, label: u.uniqueName()}))
  }

  return (
    <Form>
      <FormTypeahead name="userId"
                     placeholder="- add receiver -"
                     label=""
                     options={userOptions()}
                     value={null}
                     onInputChange={handleTypeaheadInput}
                     onChange={addReceiver}/>
      <ListGroup variant="flush">
        {receivers.map((receiver, index) => (
          <ListGroup.Item key={`receiver-${index}`}>
            {receiver.uniqueName()}
            <Button className="float-right"
                    variant="link-danger"
                    style={{padding: 0}}
                    onClick={() => removeReceiver(receiver)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Form>
  )
}

export default InstrumentAlarmModalReceivers
