import React from 'react'

const PrivacyPolicyFi = () => {
  return (
    <div>
      <h1>TeeKoo Games Oy:n tietosuojaseloste</h1>
      <p>
        TeeKoo Games Oy käsittelee ja tallentaa henkilötietoja EU:n tietosuoja-asetuksen (GDPR) sekä voimassa olevan tietosuojalain  (5.12.2018/1050) mukaisesti. Rekisterinpitäjällä on tietosuoja-asetuksen (GDPR) mukaan velvollisuus informoida selkeällä tavalla rekisteröityjä. Tämä seloste täyttää informointivelvoitteen.
      </p>
      <h2>1. Rekisterinpitäjä</h2>
      <p>
        TeeKoo Games Oy (2840799-6)<br/>
        Verkkolammintie 25 A<br/>
        83750 Sotkuma
      </p>
      <p>
        Yhteyshenkilö rekisteriä koskevissa asioissa ja tiedusteluissa:<br/>
        Jukka Hirvonen<br/>
        Puh.nro: 050 3415770<br/>
        Sähköposti:  jukka@sensormonitor.fi
      </p>
      <p>
        <strong>Rekisterin kuvaus:</strong> TeeKoo Games Oy:n asiakas- ja markkinointirekisteri
      </p>
      <h2>2. Rekisteröidyt</h2>
      <p>
        Rekisteriin tallennetaan tietoja TeeKoo Games Oy:n asiakkaista sekä ohjelmistopalveluitten käyttäjistä.
      </p>
      <h2>3. Rekisterin pitämisen peruste ja käyttötarkoitus</h2>
      <p>Rekisterin pitämisen peruste:</p>
      <ul>
        <li>henkilötietojen käsittelyperusteena on sopimussuhde.</li>
      </ul>
      <h3>Henkilötietojen käsittelyn ja rekisterin käyttötarkoitus:</h3>
      <p>
        Henkilötietoja käsitellään vain ennalta määriteltyihin tarkoituksiin, jotka ovat,
      </p>
      <ul>
        <li>asiakassuhteen luonti, ylläpito, hoitaminen, kehittäminen</li>
        <li>toimeksiantojen hoitaminen, tarjoaminen ja kehittäminen</li>
        <li>laskututus ja hallinnointi</li>
        <li>asiakkaan informointi, tekninen tuki, vikatilanteista ilmoittaminen</li>
        <li>sähköiset uutiskirjeet</li>
      </ul>
      <h2>4. Rekisteriin tallennettavat henkilötiedot</h2>
      <p>Asiakasrekisteri sisältää seuraavia henkilötietoja:</p>
      <ul>
        <li>henkilön etu- ja sukunimi</li>
        <li>sähköposti</li>
        <li>puhelinnumero</li>
      </ul>
      <p>
        Muut tiedot:
      </p>
      <ul>
        <li>yritys sekä sen osoite, postinumero ja -toimipaikka</li>
        <li>y-tunnus</li>
      </ul>
      <h2>5. Rekisteröidyn oikeudet</h2>
      <p>Rekisteröidyllä on seuraavat oikeudet:</p>
      <p>
        <strong>Tarkastusoikeus</strong><br/>
        Rekisteröity voi tarkistaa tallennetut henkilötiedot.
      </p>
      <p>
        <strong>Oikeus tietojen oikaisemiseen</strong><br/>
        Rekisteröity voi pyytää oikaisemaan häntä koskevat virheelliset tai puutteelliset tiedot.
      </p>
      <p>
        <strong>Vastustamisoikeus</strong><br/>
        Rekisteröity voi vastustaa henkilötietojen käsittelyä, mikäli kokee, että henkilötietoja on käsitelty lainvastaisesti.
      </p>
      <p>
        <strong>Suoramarkkinointikielto</strong><br/>
        Rekisteröidyllä on oikeus kieltää tietojen käyttäminen suoramarkkinointiin.
      </p>
      <p>
        <strong>Poisto-oikeus</strong><br/>
        Rekisteröidyllä on oikeus pyytää tietojen poistamista, jos tietojen käsittely ei ole tarpeen. Poistopyyntö käsitellään, jonka jälkeen joko poistamme tiedot tai ilmoitamme perustellun syyn, miksi tietoja ei voida poistaa.
      </p>
      <p>
        Rekisterinpitäjällä voi olla lakisääteinen tai muu oikeus olla poistamatta pyydettyä tietoa.
      </p>
      <p>
        <strong>Suostumuksen peruuttaminen</strong><br/>
        Jos rekisteröityä koskeva henkilötietojen käsittely perustuu ainoastaan suostumukseen, eikä esim. asiakkuuteen tai jäsenyyteen, voi rekisteröity peruuttaa suostumuksen.
      </p>
      <p>
        Rekisteröity voi valittaa päätöksestä tietosuojavaltuutetulle.
      </p>
      <p>
        Rekisteröidyllä on oikeus vaatia, että rajoitamme kiistanalaisten tietojen käsittelyä siksi aikaa, kunnes asia saadaan ratkaistua.
      </p>
      <p>
        <strong>Valitusoikeus</strong><br/>
        Rekisteröidyllä on oikeus tehdä tietosuojavaltuutetulle kantelu, jos hän kokee, että henkilötietoja käsitellessä rikotaan voimassa olevaa tietosuojalainsäädäntöä.
      </p>
      <p>
        Tietosuojavaltuutetun yhteystiedot: <a target="_blank" href="https://www.tietosuoja.fi/fi/index/yhteystiedot.html">www.tietosuoja.fi/fi/index/yhteystiedot.html</a>
      </p>
      <h2>6. Säännönmukaiset tietolähteet</h2>
      <p>Tämän rekisterin mukaiset asiakastiedot saadaan ainoastaan:</p>
      <ul>
        <li>suoraan asiakkaalta asiakas- ja sopimussuhteen syntyessä</li>
      </ul>
      <h2>7. Tietojen luovutukset</h2>
      <p>
        TeeKoo Games Oy voi luovuttaa henkilötietoja lainsäädännön sallimissa rajoissa sen yhteistyökumppaneille, ellei rekisteröity ole kieltänyt luovuttamista. Tietojen luovuttaminen voi tapahtua vain sellaisiin tarkoituksiin, jotka tukevat TeeKoo Games Oy:n asiakasrekisterin toiminta-ajatusta.
      </p>
      <p>
        Tietoja voidaan luovuttaa ulkopuoliselle myös perintään ja laskutukseen sekä toimivaltaisten viranomaisten esittämiin, voimassaolevaan lainsäädäntöön perustuviin vaatimuksiin liittyvissä asioissa.
      </p>
      <p>
        Olemme varmistaneet, että kaikki palveluntarjoajamme noudattavat tietosuojalainsäädäntöä.
      </p>
      <h2>8. Käsittelyn kesto</h2>
      <p>
        Asiakassuhteen mukaisia henkilötietoja säilytetään niin kauan kuin sopimussuhde on voimassa, sekä 2 vuotta sen päättymisestä.
      </p>
      <h2>9. Henkilötietojen käsittelijät</h2>
      <p>
        Rekisterinpitäjä ja tämän mahdolliset työntekijät käsittelevät henkilötietoja. Henkilötietojen käsittely voidaan osittain myös ulkoistaa kolmannelle osapuolelle, jolloin sopimusjärjestelyin taataan, että henkilötietoja käsitellään voimassa olevan tietosuojalainsäädännön mukaisesti ja muutoin asianmukaisesti.
      </p>
      <h2>10. Tietojen sijainti ja siirto EU:n ulkopuolelle</h2>
      <p>
        Henkilötietoja ei pääsääntöisesti siirretä EU:n tai Euroopan talousalueen ulkopuolelle. Mikäli tietoja kuitenkin siirretään, rekisterinpitäjä varmistaa, että kohdemaa täyttää tietosuojalainsäädännön kriteerit joko Privacy Shield -järjestelyn kautta tai muutoin todennetusti.
      </p>
      <p>
        Rekisteri sijaitsee suojatulla palvelimella. Asiakkaalla on oikeus saada milloin tahansa tiedot tietojen käsittelyn sijainnista.
      </p>
      <h2>11. Automaattinen päätöksenteko ja profilointi</h2>
      <p>
        Rekisterin tietoja ei käytetä automaattiseen päätöksentekoon tai rekisteröityjen profilointiin.
      </p>
      <h2>12. Rekisterin suojaus</h2>
      <p>
        Sähköisesti käsiteltävän rekisterin tiedot on suojattu ulkopuoliselta käytöltä palomuurein, henkilökohtaisin käyttäjätunnuksin ja salasanoin sekä yleisesti hyväksyttyjen teknisten keinojen avulla. Rekisterin tietoihin on pääsy ainoastaan niillä työntekijöillä, joiden työtehtävät edellyttävät näihin rekisterin tietoihin pääsyä. Kaikkia rekisterin henkilötietoja käyttäviä sitoo salassapitovelvoite. Rekisteristä ei tehdä fyysisiä kopioita. Rekisterinpitäjä tiedottaa mahdollisesta tietomurrosta välittömästi kaikille asianosaisille sekä tietosuojaviranomaiselle tietosuoja-asetuksen mukaisesti.
      </p>
      <h2>13. Muutokset</h2>
      <p>
        Tämä tietosuojaseloste voi muuttua perustuen joko ilmoitukseen rekisteröidyille tai muutokseen lainsäädännössä.
      </p>
    </div>
  )
}

export default PrivacyPolicyFi
