import React, {useState, useEffect} from 'react'
import { Organization } from "../Resources"
import FormSelect from "../lib/FormSelect"

const InstrumentModalParent = ({organization, setOrganization, parent, setParent, resource, errors, disableOrganization, disableParent, horizontal}) => {
  const [ organizations, setOrganizations ] = useState([])
  const [ groups, setGroups ] = useState([])

  useEffect(
    () => {
      Organization.perPage(200).order({'name': 'asc'}).all().then(orgs => setOrganizations(orgs.toArray()))
    }, [resource]
  )

  useEffect(
    () => {
      if (organization && resource)
        organization.instruments().where({groupInstrument: true}).perPage(200).order({'name': 'asc'}).all().then(grps =>
          setGroups(grps.toArray().filter(g =>
            ![...g.ancestors.map(a => parseInt(a.id, 10)), parseInt(g.id, 10)].includes(parseInt(resource.id, 10))
          ))
        )
      else
        setGroups([])
    }, [organization, resource]
  )

  const groupOptions = () => {
    if (!resource)
      return []
    return [[0,  '- none -']].concat(groups.map(item =>
      [item.id, [...item.ancestors.map(a => a.name), item.name].join(' - ')]
    ).sort((a, b) => {
      if (a[1] < b[1])
        return -1
      else if (a[1] > b[1])
        return 1
      else
        return 0
    }))
  }

  return (
    <React.Fragment>
      <FormSelect
        disabled={disableOrganization}
        horizontal={horizontal}
        name="organizationId"
        label="Organization"
        options={organizations.map(org => [org.id, org.fullName])}
        value={organization && organization.id}
        error={errors.organization}
        onChange={ev => {
          setOrganization(organizations.find(o => o.id === ev.target.value))
          setParent(null)
        }}/>
      <FormSelect
        disabled={disableParent}
        horizontal={horizontal}
        name="parentId"
        label="Group"
        options={groupOptions()}
        value={parent && parent.id}
        error={errors.parent}
        onChange={ev => setParent(groups.find(o => o.id === ev.target.value))}/>
    </React.Fragment>
  )
}

export default InstrumentModalParent
