import React, {useState} from 'react'
import {Table, ButtonToolbar, Button} from 'react-bootstrap'
import { UserGroup } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import UserGroupModal from "../components/UserGroupModal"
import ResourceCreateButton from "../components/ResourceCreateButton"
import ExpandableList from "../lib/ExpandableList"
import useWindowDimensions from "../hooks/useWindowDimensions"
import {useHistory} from "react-router-dom"
import {faUserTie} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

const UserGroupIndex = ({organization}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)
  const { windowWidth } = useWindowDimensions()
  const history = useHistory()

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(UserGroup.build({organization: organization}))
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const baseQuery = () => {
    if (organization)
      return organization.userGroups().includes('organization', 'roles.user')
    else
      return UserGroup.includes('organization', 'roles.user')
  }

  const UserLink = (role) => (
    <React.Fragment>
      <Button
        variant="link"
        size="sm"
        disabled={!role.user().canShow()}
        onClick={(ev) => {
          history.push(`/users/${role.user().id}`)
          ev.stopPropagation()
        }}>
        <div style={{display: 'inline-block',
          maxWidth: Math.max(100, windowWidth / 4),
          fontSize: '0.9em',
          overflow: 'hidden',
          textOverflow: 'ellipsis'}}>
          {role.user().uniqueName()}
          {role.isManager() && <FontAwesomeIcon icon={faUserTie} title="Manager" style={{marginLeft: 8}}/>}
        </div>
      </Button>
      <br/>
    </React.Fragment>
  )

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="userGroups">
          New user group
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
        <tr>
          {!organization &&
          <th>Organization</th>}
          <ResourceListSortTh name="name"/>
          <th>Users</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id}>
            {!organization &&
            <td>{resource.organization().name}</td>}
            <td>{resource.name}</td>
            <td>
              <ExpandableList>
                {resource.roles().toArray().map(role => (
                  UserLink(role)
                ))}
              </ExpandableList>
            </td>
            <td className="actions">
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)}/>
              <ResourceDeleteButton resource={resource}/>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <UserGroupModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default UserGroupIndex
