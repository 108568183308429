import React, {useContext, useEffect, useRef, useState} from "react"
import { Button } from 'react-bootstrap'
import WebSocketWrapper from "../lib/WebSocketWrapper"
import { RootContext } from "./RootContext"
import config from "../config"
import Url from "../lib/Url"

export const InstrumentStatusContext = React.createContext()

const InstrumentStatusContextProvider = ({ children }) => {
  const { auth } = useContext(RootContext)
  const [ dataTimes, _setDataTimes ] = useState({})
  const [ times, _setTimes ] = useState({})
  const [ instruments, _setInstruments ] = useState([])
  const instrumentsRef = useRef(instruments)
  const instrumentsToAddRef = useRef([])
  const instrumentsToRemoveRef = useRef([])
  const timesRef = useRef(times)
  const dataTimesRef = useRef(dataTimes)
  const wsRef = useRef(null)
  const timeoutRef = useRef(null)
  const intervalRef = useRef(null)
  const [ errorCode, setErrorCode ] = useState(null)
  const [ reconnectAttempts, setReconnectAttempts ] = useState(0)

  const setInstruments = (val) => {
    _setInstruments(val)
    instrumentsRef.current = val
  }

  const setTimes = (val) => {
    _setTimes(val)
    timesRef.current = val
  }

  const setDataTimes = (val) => {
    _setDataTimes(val)
    dataTimesRef.current = val
  }

  useEffect(() => {
    wsRef.current = initWebSocket()
    intervalRef.current = initRefresher()
    return () => {
      if (wsRef.current)
        wsRef.current.close()
      if (intervalRef.current)
        clearInterval(intervalRef.current)
      if (timeoutRef.current)
        clearTimeout(timeoutRef.current)
    }
  }, [])

  // Constantly update times so that time dependent parameters have effect in UI
  const initRefresher = () => {
    return setInterval(() => {
      let newTimes = []
      instrumentsRef.current.forEach(i => newTimes[parseInt(i.id, 10)] = Date.now())
      setTimes(newTimes)
    }, 60000)
  }

  const sendInstruments = () => {
    if ((instrumentsToAddRef.current.length === 0 && instrumentsToRemoveRef.current.length === 0) || errorCode)
      return

    if (timeoutRef.current)
      clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      if (wsRef.current){
        //const removeIds = instrumentsToRemoveRef.current.map(i => i.id)
        //  .filter(id => instrumentsToAddRef.current.map(i => i.id).indexOf(id) === -1)
        wsRef.current.sendData({
          addInstrumentIds: instrumentsToAddRef.current,
          removeInstrumentIds: instrumentsToRemoveRef.current.filter(id => instrumentsToAddRef.current.indexOf(id) === -1)
        })
        //setInstruments(instrumentsRef.current.concat(instrumentsToAddRef.current)
        //  .filter(i => removeIds.indexOf(i.id) === -1))
        instrumentsToAddRef.current = []
        instrumentsToRemoveRef.current = []
      }
    }, 500)
  }

  const initWebSocket = () => {
    const url = `${config.webSocketUrl}?jwt=${auth && auth.jwt}`
    const params = {
      reconnect: true,
      debug: process.env.NODE_ENV !== 'production' || Url.getParam('debug'),
      url: url,
      onOpen: () => {
        setErrorCode(null)
        // Add all instruments after recovery!
        instrumentsToAddRef.current = instrumentsRef.current.map(i => i.id)
        setTimeout(() => sendInstruments(), 500)
      },
      onMessage: (msg) => {
        parseMsg(msg)
      },
      onError: (evt) => {
        console.log("WebSocket error: " + evt)
      },
      onClose: (evt, attempts) => {
        if (parseInt(evt.code, 10) > 1000)
          setErrorCode(evt.code)
        setReconnectAttempts(attempts)
      }
    }
    return new WebSocketWrapper(params)
  }

  const parseMsg = (msg) => {
    try {
      const data = JSON.parse(msg)
      let newTimes = {}
      const latestData = data.instrumentLatestData || []
      latestData.forEach(d => {
        const instrument = instrumentsRef.current.find(i => parseInt(i.id, 10) === parseInt(d.id, 10))
        if (instrument && d.attributes){
          //console.log(`Latest data for ${instrument.name} ${JSON.stringify(d.attributes.values[0])} ${d.attributes.time}`)
          instrument.buildInstrumentLatestData(d.attributes)
          newTimes[parseInt(instrument.id, 10)] = Date.now()
        }
      })
      //console.log(Object.assign(timesRef.current, newTimes))
      //console.log(times)
      setTimes({...timesRef.current, ...newTimes})
      setDataTimes({...dataTimesRef.current, ...newTimes})
    } catch (e) {
      console.log("Unable to parse msg: " + e)
    }
  }

  // This is changed constantly OR when new data arrives
  const lastUpdateTime = (id) => {
    return times[parseInt(id, 10)]
  }

  // This is changed only when new data arrives
  const lastDataTime = (id) => {
    return dataTimes[parseInt(id, 10)]
  }

  // Adds instruments to context and returns ids of added instruments (already added will be ignored)
  const addInstruments = (arr) => {
    const adds = arr.filter(i => !instrumentsRef.current.find(ci => ci.id === i.id))
    setInstruments(instrumentsRef.current.concat(adds))
    instrumentsToAddRef.current = instrumentsToAddRef.current.concat(arr.map(i => parseInt(i.id, 10)))
    sendInstruments()
    if (process.env.NODE_ENV !== 'production')
      console.log("Added " + adds.length + " instruments to statusContext")
    return adds.map(i => i.id)
  }

  const removeInstruments = (ids) => {
    setInstruments(instrumentsRef.current.filter(i => ids.indexOf(i.id) === -1))
    instrumentsToRemoveRef.current = instrumentsToRemoveRef.current.concat(ids.map(id => parseInt(id, 10)))
    sendInstruments()
    if (process.env.NODE_ENV !== 'production')
      console.log("Removed " + ids.length + " instruments from statusContext")
  }

  const removeAllInstruments = () => {
    removeInstruments(instrumentsRef.current.map(i => i.id))
  }

  const defaultContext = {
    lastDataTime,
    lastUpdateTime,
    addInstruments,
    removeInstruments,
    removeAllInstruments
  }

  const reloadPage = () => {
    // delete browser cache and hard reload
    window.location.reload(true)
  }

  const shouldShowError = () => {
    return errorCode && reconnectAttempts > 4
  }

  return (
    <InstrumentStatusContext.Provider value={defaultContext}>
      {shouldShowError() &&
      <div id="websocketError">
        WebSocket error (code: {errorCode})!. Live monitoring is disabled.
        Please <Button variant="link" onClick={reloadPage}>reload the page</Button>.
      </div>}
      {children}
    </InstrumentStatusContext.Provider>
  )
}

export default InstrumentStatusContextProvider
