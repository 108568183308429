import React, { useEffect } from 'react'
import SensorMonitor from "../themes/SensorMonitor"
import TwoK from "../themes/TwoK"

export const ThemeContext = React.createContext()

const ThemeContextProvider = ({ children }) => {
  const resolveTheme = () => {
    const theme = process.env.REACT_APP_THEME
    if (theme === 'two_k') {
      return TwoK
    }
    return SensorMonitor
  }

  useEffect(() => {
    resolveTheme().init()
  }, [])

  const defaultContext = {
    Header: resolveTheme().Header,
    Footer: resolveTheme().Footer
  }

  return (
    <ThemeContext.Provider value={defaultContext}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
