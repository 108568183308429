import React, { useState } from 'react'
import FileSaver from 'file-saver'
import config from '../config'
import {Button, Spinner} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faDownload} from "@fortawesome/free-solid-svg-icons"
import styles from "../styles/InstrumentDataModal.module.css"

const InstrumentDataFileLoader = ({instrument, from, to, disabled}) => {
  const [ loading, setLoading ] = useState(false)

  const formatTime = (time) => {
    return time.toISOString().slice(0, 19).replace(/[^a-z0-9]/gi, '')
  }

  const loadCSV = () => {
    let fetchConfig = {
      method: 'GET',
      headers: {
        'Accept': 'text/csv',
        'Content-Type': 'text/csv',
        'Authorization': `Bearer ${JSON.parse(window.localStorage.getItem('auth') || '{}').jwt}`
      }
    }
    let url = `${config.apiUrl}/instrument_data_exports/${instrument.id}?from=${from.toISOString()}&to=${to.toISOString()}`

    setLoading(true)

    fetch(url, fetchConfig).then((response) => (
      response.blob()
    )).then((blob) => {
      let filename = instrument.fullName.replace(/[^a-z0-9]/gi, '_').toLowerCase() + '-'
      filename += `${formatTime(from)}-${formatTime(to)}`
      console.log("Saving CSV as " + filename)
      FileSaver.saveAs(blob, `${filename}.csv`)
      setLoading(false)
    })
  }

  return (
    <Button size="sm" variant="dark" onClick={() => loadCSV()} disabled={loading || disabled}>
      <FontAwesomeIcon icon={faDownload}/>&nbsp;CSV
      {loading &&
      <Spinner size="sm" animation="border" role="status" className={styles.spinner}>
        <span className="sr-only">Loading...</span>
      </Spinner>}
    </Button>
  )
}

export default InstrumentDataFileLoader
