import React, {useContext, useEffect, useState} from "react"
import {ResourceContext} from "../contexts/ResourceContext"
import ResourceModal from "../components/ResourceModal"
import uncamelize from "../lib/uncamelize"

const withResourceModal = (WrappedComponent, options) => ({resource, onClose, show}) => {
  const [ formState, setFormState ] = useState({})
  const [ errors, setErrors ] = useState({})
  const { reloadResources } = useContext(ResourceContext)

  useEffect(
    () => {
      if (resource){
        let initialState = {}
        resource.editableAttributes().forEach(attr => initialState[attr] = resource[attr])
        resource.belongsToAssociations().forEach(attr => initialState[attr] = eval(`resource.${attr}()`))
        setFormState(initialState)
      }
      setErrors({})
    },
    [resource]
  )

  const label = () => {
    return resource && uncamelize(resource.constructor.className)
  }

  const updateMsg = () => {
    if (!resource)
      return ''
    return `${label()} updated`
  }

  const createMsg = () => {
    if (!resource)
      return ''
    return `${label()} created`
  }

  const handleInputChange = (ev) => {
    const name = ev.target.name
    let value = ev.target.value
    if (ev.target.type === 'checkbox')
      value = ev.target.checked
    setFormState({...formState, [name]: value})
  }

  const save = () => {
    resource.assignAttributes(formState)
    const msg = resource.persisted() ? updateMsg() : createMsg()
    return resource.save().then(() => {
      reloadResources(msg)
      onClose()
    }).catch(err => {
      let errors = {}
      resource.errors().toArray().forEach(error => errors[error.field] = error.detail)
      setErrors(errors)
    })
  }

  const title = () => {
    if (options && options.createTitle && resource && !resource.persisted())
      return options.createTitle
    else if (options && options.updateTitle && resource && resource.persisted())
      return options.updateTitle
    else
      return null
  }

  const submitLabel = () => {
    if (options && options.createSubmitLabel && resource && !resource.persisted())
      return options.createSubmitLabel
    else if (options && options.updateSubmitLabel && resource && resource.persisted())
      return options.updateSubmitLabel
    else
      return null
  }

  return (
    <ResourceModal show={show}
                   onClose={onClose}
                   onSubmit={save}
                   resource={resource}
                   submitLabel={submitLabel()}
                   title={title()}
                   label={label() && label().toLowerCase()}>
      <WrappedComponent formState={formState}
                        errors={errors}
                        resource={resource}
                        handleInputChange={handleInputChange}/>
    </ResourceModal>
  )
}

export default withResourceModal
