import {DeviceConfig} from "../Resources"
import ResourceContext from "../contexts/ResourceContext"
import {ButtonToolbar, Table} from "react-bootstrap"
import ResourceCreateButton from "../components/ResourceCreateButton"
import ResourceListSortTh from "../components/ResourceListSortTh"
import ResourceList from "../components/ResourceList"
import ResourceEditButton from "../components/ResourceEditButton"
import ResourceDisableButton from "../components/ResourceDisableButton"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceListPagination from "../components/ResourceListPagination"
import DeviceConfigModal from "../components/DeviceConfigModal"
import Time from "../lib/Time"
import React, {useState} from "react"

const DeviceConfigIndex = () => {
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(DeviceConfig.build())
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  return (
    <ResourceContext baseQuery={DeviceConfig} defaultSort={{deviceName: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="automations">
          New device config
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
          <tr>
            <ResourceListSortTh name="deviceName"/>
            <th>Description</th>
            <th>Updated at</th>
            <th className="actions"></th>
          </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id} className={'clickable'}>
            <td>{resource.deviceName}</td>
            <td>{resource.description}</td>
            <td><Time>{resource.updatedAt}</Time></td>
            <td className="actions">
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)} />
              <ResourceDisableButton resource={resource} />
              <ResourceDeleteButton resource={resource} />
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <DeviceConfigModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default DeviceConfigIndex
