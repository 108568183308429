import React from 'react'
import {Form} from 'react-bootstrap'
import FormInput from '../lib/FormInput'
import withResourceModal from "../hocs/withResourceModal"

const DownlinkPresetModal = ({formState, errors, handleInputChange}) => {
  return (
    <Form>
      <FormInput name="deviceModel" value={formState.deviceModel} error={errors.deviceModel} onChange={handleInputChange}/>
      <FormInput name="name" value={formState.name} error={errors.name} onChange={handleInputChange}/>
      <FormInput name="payload" value={formState.payload} error={errors.payload} onChange={handleInputChange}/>
      <FormInput name="port" value={formState.port} error={errors.port} onChange={handleInputChange}/>
    </Form>
  )
}

export default withResourceModal(DownlinkPresetModal)
