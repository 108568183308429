const uncamelize = (str, upperFirst = true, replaceSpace = null) => {
  let ret = str.replace(/([a-z0-9])([A-Z])/g, '$1 $2').replace(/\b([A-Z0-9]+)([A-Z0-9])([a-z0-9])/, '$1 $2$3')
  ret = ret.toLowerCase()
  if (upperFirst)
    ret = ret.charAt(0).toUpperCase() + ret.slice(1)
  if (replaceSpace)
    ret = ret.replace(/ /g, replaceSpace)
  return ret
}

export default uncamelize
