import React from 'react'
import {Form} from "react-bootstrap"

import FormGroup from "./FormGroup"

const FormSelect = ({name, label, value, onChange, error, options, disabled, blank, horizontal, className, size, selectStyle, icon}) => {
  const optionArray = () => {
    if (Array.isArray(options) && options.length > 0 && Array.isArray(options[0]))
      return options
    else if (Array.isArray(options))
      return options.map(o => [o, o])
    else if (typeof options === 'object')
      return Object.keys(options).map(k => [k, options[k]])
    else
      return []
  }

  return (
    <FormGroup name={name} label={label} horizontal={horizontal} className={className} icon={icon}>
      <Form.Control size={size || ''}
                    disabled={disabled}
                    name={name}
                    as="select"
                    style={selectStyle}
                    value={value || ''}
                    onChange={onChange}
                    className={error ? 'is-invalid' : ''}>
        {blank && <option value="">{blank}</option>}
        {optionArray().map(item => (
          <option key={`${name}-option-${item[0]}`} value={item[0]}>{item[1]}</option>
        ))}
      </Form.Control>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
    </FormGroup>
  )
}

export default FormSelect
