import React, {useContext} from 'react'
import ResourceModal from "./ResourceModal"
import {RootContext} from "../contexts/RootContext"

const CommandRunModal = ({resource, show, onClose}) => {
  const { showMessage } = useContext(RootContext)

  const runCommand = () => {
    return resource.postRun().then(() => {
      showMessage('Command ran successfully')
      onClose()
    }).catch(response => {
      if (response.errors)
        console.error(response.errors)
      showMessage('Error running command!', 'danger')
      onClose()
    })
  }

  return (
    <ResourceModal show={show}
                   onClose={onClose}
                   onSubmit={runCommand}
                   resource={resource}
                   title="Run command"
                   submitLabel="Run">
      <h5>{resource && resource.name}</h5>
      <p>{resource && resource.description}</p>
    </ResourceModal>
  )
}

export default CommandRunModal
