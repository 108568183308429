import React, { useContext } from 'react'
import { ResourceContext } from "../contexts/ResourceContext"

const ResourceList = ({children}) => {
  const { resources } = useContext(ResourceContext)

  return (
    <React.Fragment>
      {resources && resources.toArray().map(resource => children(resource))}
    </React.Fragment>
  )
}

export default ResourceList
