import React, { useState, useEffect, useContext } from 'react'
import ResourceContext from '../contexts/ResourceContext'
import { Instrument, Organization } from "../Resources"
import InstrumentMap from '../components/InstrumentMap'
import InstrumentDashboard from '../components/InstrumentDashboard'
import ResourceFilter from "../components/ResourceFilter"
import InstrumentStatusContext from "../contexts/InstrumentStatusContext"
import InstrumentMonitorFilter from "../components/InstrumentMonitorFilter"
import { RootContext } from "../contexts/RootContext"

const InstrumentMonitor = ({view}) => {
  const [ organizations, setOrganizations ] = useState([]) // For filter
  const [ isLoading, setLoading ] = useState(true)
  const [ multipleOrganizations, setMultipleOrganizations ] = useState(false)
  const { canIndex } = useContext(RootContext)

  useEffect(
    () => {
      const promise = loadOrganizations("")
      if (promise === null) {
        setLoading(false)
        return
      }
      promise.finally(() => setLoading(false))
    },
    []
  )

  const loadOrganizations = (search) => {
    if (!canIndex('organizations')) {
      return null
    }
    return Organization.where({search}).order({name: 'asc'}).perPage(200).all().then(result => {
      setOrganizations(result.toArray())
      if (result.size() > 1) {
        setMultipleOrganizations(true)
      }
    })
  }

  if (isLoading) {
    return null
  }

  const baseQuery = () => {
    return Instrument.where({root: true}).order({name: 'asc'}).perPage(200)
  }

  return (
    <ResourceContext
      reloadInterval={1000 * 60 * 60}
      baseQuery={baseQuery()}
      mandatoryFilters={multipleOrganizations ? ['organization.id'] : []}
    >
      <ResourceFilter>
        {(filter, handleFilterChange) => (
          <InstrumentMonitorFilter
            multipleOrganizations={multipleOrganizations}
            organizations={organizations}
            filter={filter}
            handleFilterChange={handleFilterChange}
            loadOrganizations={loadOrganizations}
          />
        )}
      </ResourceFilter>
      <InstrumentStatusContext>
        {view === 'map' &&
        <InstrumentMap/>}
        {view === 'dashboard' &&
        <InstrumentDashboard/>}
      </InstrumentStatusContext>
    </ResourceContext>
  )
}

export default InstrumentMonitor
