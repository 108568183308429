import React from "react"
import { useContext } from "react"
import { ThemeContext } from "../contexts/ThemeContext"

const Footer = () => {
  const { Footer } = useContext(ThemeContext)

  return (
    <Footer/>
  )
}

export default Footer
