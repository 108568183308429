import React from 'react'
import styles from '../styles/Breadcrumbs.module.css'
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Breadcrumbs = ({children, hover, size}) => {
  return (
    <div
      className={hover ? styles.containerHover : styles.container}
      style={{fontSize: size === 'sm' ? '0.85em' : '1em'}}
    >
      {React.Children.map(children, (child, i) => {
        if (i < React.Children.count(children) - 1)
          return <span className={styles.wrapper}>
            <span className={styles.item}>{child}</span>
            <FontAwesomeIcon className={styles.separator} icon={faChevronRight} />
          </span>
        else
          return <span className={styles.wrapper}>
            <span className={[styles.item, styles.currentItem].join(' ')}>{child}</span>
          </span>
      })}
    </div>
  )
}

export default Breadcrumbs
