import React, {useContext, useState} from 'react'
import {Table, ButtonToolbar} from 'react-bootstrap'
import { OrganizationDevice } from "../Resources"
import ResourceList from "../components/ResourceList"
import ResourceListSortTh from '../components/ResourceListSortTh'
import ResourceContext from "../contexts/ResourceContext"
import ResourceListPagination from "../components/ResourceListPagination"
import ResourceDeleteButton from "../components/ResourceDeleteButton"
import ResourceEditButton from "../components/ResourceEditButton"
import OrganizationDeviceModal from "../components/OrganizationDeviceModal"
import ResourceCreateButton from "../components/ResourceCreateButton"
import Time from "../lib/Time"
import {RootContext} from '../contexts/RootContext'

const OrganizationDeviceIndex = ({organization}) => {
  const { auth } = useContext(RootContext)
  const [showModal, setShowModal] = useState(false)
  const [selectedResource, setSelectedResource] = useState(null)

  const editResource = (resource) => {
    setSelectedResource(resource)
    setShowModal(true)
  }

  const newResource = () => {
    setSelectedResource(OrganizationDevice.build({organization: organization, validFrom: new Date()}))
    setShowModal(true)
  }

  const closeModal = () => {
    setSelectedResource(null)
    setShowModal(false)
  }

  const baseQuery = () => {
    if (organization)
      return organization.organizationDevices().includes('organization')
    else
      return OrganizationDevice.includes('organization')
  }

  const disableCreate = () => {
    return !auth.user.admin && !organization.isChild
  }

  return (
    <ResourceContext baseQuery={baseQuery()} defaultSort={{name: 'asc'}}>
      <ButtonToolbar className="float-right">
        <ResourceCreateButton onClick={newResource} endpoint="organizationDevices" disabled={disableCreate()}>
          Add device
        </ResourceCreateButton>
      </ButtonToolbar>
      <Table striped bordered hover>
        <thead>
        <tr>
          {!organization &&
          <th>Organization</th>}
          <ResourceListSortTh name="name">ID</ResourceListSortTh>
          <th>App EUI</th>
          <th>App Key</th>
          <th>Remark</th>
          <th>Valid from</th>
          <th className="actions"></th>
        </tr>
        </thead>
        <tbody>
        <ResourceList>
          {resource => <tr key={resource.id}>
            {!organization &&
            <td>{resource.organization().name}</td>}
            <td>{resource.name}</td>
            <td>{resource.appEui}</td>
            <td>{resource.appKey}</td>
            <td>{resource.remark}</td>
            <td><Time timeFormat={null}>{resource.validFrom}</Time></td>
            <td className="actions">
              <ResourceEditButton resource={resource} onClick={() => editResource(resource)}/>
              <ResourceDeleteButton resource={resource}/>
            </td>
          </tr>}
        </ResourceList>
        </tbody>
      </Table>
      <ResourceListPagination/>
      <OrganizationDeviceModal resource={selectedResource} show={showModal} onClose={closeModal}/>
    </ResourceContext>
  )
}

export default OrganizationDeviceIndex
