import React, { useContext } from 'react'
import { Dropdown, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, faCopy, faEdit, faEyeSlash, faTimesCircle, faWrench, faEye } from "@fortawesome/free-solid-svg-icons"
import { RootContext } from "../contexts/RootContext"
import { ResourceContext } from "../contexts/ResourceContext"
import styles from '../styles/InstrumentActionsButton.module.css'

const InstrumentActionsButton = ({resource, onEdit, onClone, onTakeOffline, onTakeOnline, variant}) => {
  const { canCreate } = useContext(RootContext)
  const { reloadResources } = useContext(ResourceContext)

  const disableResource = (event) => {
    resource.assignAttributes({disabled: true})
    resource.save().then(() => {
      reloadResources("Resource disabled")
    }).catch(err => {
      console.error(err)
    })
    event.stopPropagation()
  }

  const enableResource = (event) => {
    resource.assignAttributes({disabled: false})
    resource.save().then(() => {
      reloadResources("Resource enabled")
    }).catch(err => {
      console.error(err)
    })
    event.stopPropagation()
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
      title="Instrument actions"
      variant={variant || 'link'}
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick(e)
      }}
    >
      {children}
    </Button>
  ))

  return (
    <Dropdown className={styles.dropdown}>
      <Dropdown.Toggle
        disabled={!resource.canUpdate()}
        as={CustomToggle}
      >
        <FontAwesomeIcon icon={faWrench} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={onEdit}>
          <span className={styles.icon}><FontAwesomeIcon icon={faEdit} /></span>
          <span>Edit</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={onClone} disabled={!canCreate('instruments')}>
          <span className={styles.icon}><FontAwesomeIcon icon={faCopy} className={styles.icon} /></span>
          <span>Clone</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={resource.disabledAt ? enableResource : disableResource}>
          <span className={styles.icon}>
            {resource.disabledAt &&
            <FontAwesomeIcon icon={faCheckSquare} className={styles.icon} />}
            {!resource.disabledAt &&
            <FontAwesomeIcon icon={faTimesCircle} className={styles.icon} />}
          </span>
          <span>{resource.disabledAt ? 'Enable' : 'Disable'}</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={onTakeOffline}>
          <span className={styles.icon}><FontAwesomeIcon icon={faEyeSlash} className={styles.icon} /></span>
          <span>Take offline</span>
        </Dropdown.Item>
        <Dropdown.Item onClick={onTakeOnline}>
          <span className={styles.icon}><FontAwesomeIcon icon={faEye} className={styles.icon} /></span>
          <span>Put online</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default InstrumentActionsButton
