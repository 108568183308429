import React, { useEffect, useState } from "react"
import {Button, Form, ListGroup} from "react-bootstrap"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faTimes} from "@fortawesome/free-solid-svg-icons"
import FormSelect from "../lib/FormSelect"
import Tag from "../resources/Tag"

const InstrumentModalTags = ({selectedTags, setSelectedTags, resource}) => {
  const [ tags, setTags ] = useState([])

  useEffect(
    () => {
      Tag.where({'organization.id': resource.organization().id}).limit(1000).order({'name': 'asc'}).all().then(tags => {
        setTags(tags.toArray())
      })
      if (resource.persisted() && resource.tags && resource.tags())
        setSelectedTags(resource.tags().toArray())
    },
    [resource]
  )

  const addTag = (ev) => {
    const value = ev.target.value
    const tag = tags.find(tag => parseInt(tag.id, 10) === parseInt(value, 10))
    setSelectedTags(selectedTags.concat(tag))
  }

  const removeTag = (tag) => {
    setSelectedTags(selectedTags.filter(ug => ug !== tag))
  }

  const tagOptions = () => {
    const excludeIds = selectedTags.map(r => r.id)
    return tags.filter(tag => !excludeIds.includes(tag.id)).map(tag => [tag.id, tag.name])
  }

  const tagsArePresent = () => {
    return selectedTags.length > 0 || tagOptions().length > 0
  }

  return (
    <Form>
      {tagsArePresent() && <React.Fragment>
      <ListGroup variant="flush">
        {selectedTags.map((tag, index) => (
          <ListGroup.Item key={`tag-${index}`}>
            {tag.name}
            <Button className="float-right" variant="link-danger" size="sm" onClick={() => removeTag(tag)}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {tagOptions().length > 0 &&
      <FormSelect
        name="tagId"
        label=""
        options={tagOptions()}
        blank="+ add a tag"
        onChange={addTag}/>}
      </React.Fragment>}
      {!tagsArePresent() && <p>- organization has no tags defined -</p>}
    </Form>
  )
}

export default InstrumentModalTags
