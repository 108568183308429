import React, {useEffect, useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import WorldMap from "../lib/WorldMap"
import Time from "../lib/Time"
import styles from "../styles/InstrumentMap.module.css"

const InstrumentDataMap = ({data, className, visible}) => {
  const [ fitBounds, setFitBounds ] = useState(true)

  useEffect(
    () => {
      if (data){
        setFitBounds(true)
        window.setTimeout(() => setFitBounds(false), 2000)
      }
    },
    [data]
  )

  const MarkerLabel = ({children}) => (
    <div className={styles.markerLabel}>
      {children}
    </div>
  )

  const resolveMarker = (datum, startOrEnd = false) => {
    return {
      lat: parseFloat(datum.lat),
      lng: parseFloat(datum.lon),
      icon: null,
      title: `${Time.formatTime(datum.time)}`,
      labels: startOrEnd ?
        [<MarkerLabel>{Time.formatTime(datum.time, 'days', 'minutes')}</MarkerLabel>] :
        [<MarkerLabel>{Time.formatTime(datum.time, null, 'minutes')}</MarkerLabel>]
    }
  }

  const route = () => {
    let d = []
    let lat = null
    let lon = null
    let dist = null
    data.sort((a, b) => a.time - b.time).forEach(datum => {
      if (datum &&
        datum.lon !== undefined &&
        datum.lon !== null &&
        datum.lat !== undefined &&
        datum.lat !== null &&
        (datum.lon !== lon || datum.lat !== lat)){

        if (lat !== null && lon !== null)
          dist = distance(lat, lon, datum.lat, datum.lon)

        // Minimum node distance is 50m
        if (dist === null || dist > 0.05){
          d.push(Object.assign({dist: dist}, datum))
          lat = datum.lat
          lon = datum.lon
        }
      }
    })
    return d
  }

  const markers = () => {
    if (!data)
      return []
    let markersArray = []
    route().forEach((datum, index) => {
      markersArray.push(resolveMarker(datum, index === 0 || index === route().length - 1))
    })
    return markersArray
  }

  const paths = () => {
    let path = []
    route().forEach(datum => {
      path.push({lat: datum.lat, lng: datum.lon})
    })
    return [path]
  }

  //calculate haversine distance for linear distance
  const distance = (lat1, lon1, lat2, lon2) => {
    const p = 0.017453292519943295    // Math.PI / 180
    const c = Math.cos
    const a = 0.5 - c((lat2 - lat1) * p)/2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p))/2

    return 12742 * Math.asin(Math.sqrt(a)) // 2 * R; R = 6371 km
  }

  const travelDistance = () => {
    const data = route()
    if (data.length < 2)
      return 0
    return data.reduce((s, i) => s + i.dist, 0)
  }

  const travelTime = () => {
    const data = route()
    if (data.length < 2)
      return 0
    const t1 = data[0].time
    const t2 = data[data.length - 1].time
    return t2 - t1
 }

  const travelSpeed = () => {
    const dist = travelDistance()
    const time = travelTime()
    if (dist === 0 || time === 0)
      return 0
    return dist / (time  / (1000 * 60 * 60))
  }

  return (
    <React.Fragment>
      <div className={className}>
        <WorldMap mapContainerStyle={{height: '350px'}}
                  paths={paths()}
                  markers={markers()}
                  fitBounds={fitBounds}/>
      </div>
      {travelDistance() > 0 && visible &&
      <Container fluid>
        <Row style={{fontSize: "0.9em"}}>
          <Col md='auto'>
            Travel distance:&nbsp;
            <strong>{travelDistance().toFixed(1)}&nbsp;km</strong>
          </Col>
          <Col md='auto'>
            Time:&nbsp;
            <strong><Time.Duration>{travelTime()}</Time.Duration></strong>
          </Col>
          <Col md='auto'>
            Speed:&nbsp;
            <strong>{travelSpeed().toFixed(1)}&nbsp;km/h</strong>
          </Col>
        </Row>
      </Container>}
    </React.Fragment>
  )

}

export default InstrumentDataMap
