import React, { useContext } from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckSquare, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { ResourceContext } from "../contexts/ResourceContext"

const ResourceDisableButton = ({resource, label, variant, size}) => {
  const { reloadResources } = useContext(ResourceContext)

  const disableResource = (event) => {
    resource.assignAttributes({disabled: true})
    resource.save().then(() => {
      reloadResources("Resource disabled")
    }).catch(err => {
      console.error(err)
    })
    event.stopPropagation()
  }

  const enableResource = (event) => {
    resource.assignAttributes({disabled: false})
    resource.save().then(() => {
      reloadResources("Resource enabled")
    }).catch(err => {
      console.error(err)
    })
    event.stopPropagation()
  }

  const toggle = (event) => {
    if (resource.disabledAt)
      enableResource(event)
    else
      disableResource(event)
  }

  const resolveLabel = () => {
    return resource.disabledAt ? 'Enable' : 'Disable'
  }

  return (
    <React.Fragment>
      <Button disabled={!resource.canUpdate()}
              onClick={toggle}
              size={size || ''}
              variant={variant || 'link'}
              title={resolveLabel()}>
        {resource.disabledAt &&
        <FontAwesomeIcon icon={faCheckSquare} />}
        {!resource.disabledAt &&
        <FontAwesomeIcon icon={faTimesCircle} />}
        {label &&
        <span>&nbsp;{resolveLabel()}</span>}
      </Button>
    </React.Fragment>
  )
}

export default ResourceDisableButton
