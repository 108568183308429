import React, {useState} from 'react'
import {Button, Form} from "react-bootstrap"
import FormGroup from './FormGroup'

const FormFile = ({name, label, onChange, error, horizontal, placeholder, size, disabled, fileSelected}) => {
  const [isValid, setIsValid] = useState(null)

  const fileLabel = () => {
    const length = 20
    return placeholder.length > length ?
      placeholder.substring(0, length - 3) + "..." :
      placeholder
  }

  const handleChange = (event) => {
    if (event === null) {
      onChange(null)
      setIsValid(null)
      return
    }
    const name = event.target.files[0].name
    const parts = name.split('.')
    if (!['jpeg', 'jpg', 'png'].includes(parts[parts.length - 1].toLowerCase())) {
      onChange(null)
      setIsValid(false)
      return
    }
    onChange(event)
    setIsValid(true)
  }

  return (
    <FormGroup name={name} label={label} horizontal={horizontal} size={size}>
      <Form.File disabled={disabled}
                 custom
                 isValid={isValid === true}
                 isInvalid={isValid === false}
                 id={name}
                 label={fileLabel()}
                 onChange={handleChange}
                 className={error ? 'is-invalid' : ''}/>
      {error && <Form.Text className="text-danger">{error}</Form.Text>}
      {isValid === false && <Form.Text className="text-danger">Invalid file type</Form.Text>}
      {fileSelected &&
      <Button size="sm" variant="outline-danger" onClick={() => onChange(null)} style={{float: 'right', marginTop: 4}}>
        Remove background
      </Button>}
    </FormGroup>
  )
}

export default FormFile
