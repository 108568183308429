import React, {useRef, useState, useEffect, useContext} from "react"
import {Col, Dropdown, Form, Row} from "react-bootstrap"
import FormTypeahead from "../lib/FormTypeahead"
import FormSelect from "../lib/FormSelect"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import styles from "../styles/InstrumentMap.module.css"
import {faCheck, faExclamationTriangle, faTimes} from "@fortawesome/free-solid-svg-icons"
import {Instrument, Tag} from "../Resources"
import Url from "../lib/Url"
import {RootContext} from "../contexts/RootContext"

const InstrumentMonitorFilter = ({organizations, filter, loadOrganizations, handleFilterChange, multipleOrganizations}) => {
  const [ instruments, setInstruments ] = useState([])
  const [ tags, setTags ] = useState([])
  const { auth, canIndex } = useContext(RootContext)
  const [ alarmedExist, setAlarmedExist ] = useState(false)
  const intervalRef = useRef(null)
  const organizationId = filter['organization.id']
  const excludedTags = (filter['tag.name.not'] || '').split(',')

  useEffect(() => {
    if (!Url.getParam('filter')) {
      const defaultFilter = window.localStorage.getItem('monitorFilter')
      if (defaultFilter) {
        window.setTimeout(() => handleFilterChange(null, JSON.parse(defaultFilter)[auth.user.id]), 100)
      }
    }
    return () => {
      window.clearInterval(intervalRef.current)
    }
  }, [])

  useEffect(() => {
    window.clearInterval(intervalRef.current)
    if (multipleOrganizations && (!organizationId || organizationId === '')) {
      setAlarmedExist(false)
      setInstruments([])
      handleFilterChange(null, {'alarm_status_root': undefined})
    } else {
      checkIfAlarmedExist(organizationId)
      intervalRef.current = window.setInterval(() => checkIfAlarmedExist(organizationId), 30000)
      loadInstruments("")
      loadTags()
    }
  }, [organizationId])

  useEffect(() => {
    storeFilter()
  }, [filter])

  const storeFilter = () => {
    window.localStorage.setItem(
      'monitorFilter',
      JSON.stringify(
        {[auth.user.id]: {
            'organization.id': organizationId,
            '_organization.name': filter['_organization.name'],
            'tag.name.not': filter['tag.name.not']
        }}
      )
    )
  }

  const loadInstruments = (search) => {
    Instrument.where({search, root: true, 'organization.id': filter['organization.id']}).order({name: 'asc'}).perPage(200).all().then(result => {
      setInstruments(result.toArray())
    })
  }

  const loadTags = () => {
    if (!canIndex('tags')) {
      return
    }
    Tag.where({'organization.id': filter['organization.id']}).order({name: 'asc'}).perPage(200).all().then(result => {
      setTags(result.toArray())
    })
  }

  const checkIfAlarmedExist = (organizationId) => {
    Instrument.where({alarm_status: 'alarmed', 'organization.id': organizationId}).perPage(1).all().then(result => {
      setAlarmedExist(!result.empty())
    })
  }

  const instrumentIdByName = (name) => {
    const instrument = instruments.find(i => i.name === name)
    return instrument ? instrument.id : undefined
  }

  const organizationIdByName = (name) => {
    const organization = organizations.find(i => i.fullName === name)
    return organization ? organization.id : undefined
  }

  const toggleTag = (name) => {
    let tags = excludedTags || []
    if (tags.includes(name)) {
      tags = tags.filter(tag => tag !== name)
    } else {
      tags = [...tags, name]
    }
    handleFilterChange(null, {'tag.name.not': tags.filter(tag => tag && tag !== '').join(',')})
  }

  return (
    <Form>
      <Row className="justify-content-md-left">
        {multipleOrganizations &&
        <Col md="auto" style={{width: 630}}>
          <FormTypeahead name="_organization.name"
                         label="Organization"
                         placeholder={'- select -'}
                         options={organizations.map(org => org.fullName)}
                         value={filter['_organization.name']}
                         clearButton
                         horizontal={9}
                         onInputChange={loadOrganizations}
                         onChange={(ev) => handleFilterChange(ev, {'organization.id': organizationIdByName(ev.target.value)})}/>
        </Col>}
        <Col md="auto">
          <FormTypeahead name="_name"
                         label="Target"
                         placeholder="- all -"
                         options={instruments.map(instrument => instrument.name)}
                         value={filter['_name']}
                         clearButton
                         horizontal={'auto'}
                         onInputChange={loadInstruments}
                         onChange={(ev) => handleFilterChange(ev, {id: instrumentIdByName(ev.target.value)})}/>
        </Col>
        {alarmedExist &&
        <Col md="auto">
          <FormSelect name="alarm_status_root"
                      icon={<FontAwesomeIcon title="Some instruments have triggered alarms!" className={styles.alarmedExistSymbol} icon={faExclamationTriangle} />}
                      label="Status"
                      options={[['alarmed', 'alarmed'], ['not_alarmed', 'not alarmed']]}
                      blank="- any -"
                      horizontal={'auto'}
                      selectStyle={{minWidth: 0}}
                      value={filter.alarm_status_root}
                      onChange={handleFilterChange}/>
        </Col>}
        {tags.length > 0 &&
        <Dropdown>
          <Dropdown.Toggle style={{marginLeft: 10, height: 35, lineHeight: '20px'}} variant="dark">
            Tags
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {tags.map(tag =>
              <Dropdown.Item key={tag.name} onClick={() => toggleTag(tag.name)}>
                <FontAwesomeIcon icon={excludedTags.includes(tag.name) ? faTimes : faCheck} style={{marginRight: 10}}/>
                {tag.name}
              </Dropdown.Item>)}
          </Dropdown.Menu>
        </Dropdown>}
      </Row>
    </Form>
  )
}

export default InstrumentMonitorFilter
