import React, { useEffect, useState, useContext } from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { RootContext } from "../contexts/RootContext"
import { useHistory } from "react-router-dom"

const Menu = () => {
  const [ active, setActive ] = useState('home')
  const { isAuthenticated, defaultView, canIndex, auth } = useContext(RootContext)
  const history = useHistory()

  useEffect(
    () => {
      resolveActive(window.location)
      return history.listen(location => resolveActive(location))
    }, [history]
  )

  const resolveActive = (location) => {
    const parts = location.pathname.split('/')
    if (parts.length > 1 && parts[1] !== '')
      setActive(parts[1].split('?')[0])
  }

  const resolveClass = (key) => {
    if (key === 'organizations' && active === 'instruments')
      return 'main-nav-link-active'
    else if (key === 'home' && (active === 'dashboard' || active === 'map'))
      return 'main-nav-link-active'
    return active === key ? 'main-nav-link-active' : 'main-nav-link'
  }

  return (
    <div>
    {isAuthenticated() && canIndex('organizations') &&
    <Navbar className="main-nav" expand="md">
      <Navbar.Toggle aria-controls="mainMenu"/>
      <Navbar.Collapse id="mainMenu">
        <Nav className="mr-auto" onSelect={(item) => setActive(item)}>
          <Nav.Item>
            <LinkContainer to={`/${defaultView}`}>
              <Nav.Link eventKey="home" className={resolveClass('home')}>Home</Nav.Link>
            </LinkContainer>
          </Nav.Item>
          {canIndex('organizations') &&
          <Nav.Item>
            <LinkContainer to="/organizations">
              <Nav.Link eventKey="organizations" className={resolveClass('organizations')}>Organizations</Nav.Link>
            </LinkContainer>
          </Nav.Item>}
          {canIndex('users') &&
          <Nav.Item>
            <LinkContainer to="/users">
              <Nav.Link eventKey="users" className={resolveClass('users')}>Users</Nav.Link>
            </LinkContainer>
          </Nav.Item>}
          {canIndex('devices') &&
          <Nav.Item>
            <LinkContainer to="/devices">
              <Nav.Link eventKey="devices" className={resolveClass('devices')}>Devices</Nav.Link>
            </LinkContainer>
          </Nav.Item>}
          {canIndex('downlinkPresets') && auth.user.admin &&
          <Nav.Item>
            <LinkContainer to="/downlink_presets">
              <Nav.Link eventKey="downlink_presets" className={resolveClass('downlink_presets')}>Downlink presets</Nav.Link>
            </LinkContainer>
          </Nav.Item>}
          {canIndex('downlinkPresets') && auth.user.admin &&
          <Nav.Item>
            <LinkContainer to="/device_activity_report">
              <Nav.Link eventKey="device_activity_report" className={resolveClass('device_activity_report')}>Report</Nav.Link>
            </LinkContainer>
          </Nav.Item>}
          {canIndex('deviceConfigs') &&
          <Nav.Item>
            <LinkContainer to="/device_configs">
              <Nav.Link eventKey="device_configs" className={resolveClass('device_configs')}>Device configs</Nav.Link>
            </LinkContainer>
          </Nav.Item>}
          {canIndex('eventLogs') &&
            <Nav.Item>
              <LinkContainer to="/log">
                <Nav.Link eventKey="log" className={resolveClass('log')}>Log</Nav.Link>
              </LinkContainer>
            </Nav.Item>}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    }
    </div>
  )
}

export default Menu
